
.homepage-story {
  @extend .flex-center;
  background-color: $color-primary-offwhite;
}

#homepage-story-card {
  padding: 5rem 2.5rem;
  position: relative;
  background-color: #151515;

  



  @include media-breakpoint-up(md) {
    padding-top: 10rem;
  padding-bottom: 8rem !important;
  position: relative;
  background-color: #333333;
  }

  .absolute-pixel--1 {
    top: 0;
    left: 0;

    @include media-breakpoint-up(md) {
      left: 4rem;
    }

    .pixel--1 {
      top: 0;
      left: 0;
      background-color: $color-primary-dark;
      @include media-breakpoint-up(md) {
        background-color: $color-accent;
      }
    }

    .pixel--2 {
      top: 2rem;
      left: 2rem;
      background-color: $color-accent;
      @include media-breakpoint-up(md) {
        background-color: $color-primary-dark;
      }
    }

    .pixel--3 {
      top: 0;
      left: 4rem;
      background-color: $color-white;
      @include media-breakpoint-up(md) {
        background-color: $color-primary-dark;
      }
    }
  }

  .absolute-pixel--2 {
    bottom: 0;
    right: 0;

    @include media-breakpoint-up(md) {
      right: 4rem;
    }

    .pixel--1 {
      bottom: 2rem;
      right: 0;
      background-color: $color-primary-dark;
    }

    .pixel--2 {
      bottom: 0;
      right: 2rem;
      background-color: $color-white;
      @include media-breakpoint-up(md) {
        background-color: $color-accent;
      }
    }
  }

}


.stories-carousel-mobile  {
  background-color: #00000066
}

.stories-carousel-mobile-testi  {
  background-color: #00000066
}

.homepage-story-card {
  @extend .p-3;

  position: relative;

  &--header {
    text-align: center;
  }

  &--copy {
    @extend .pt-5;

    text-align: left;

    @include media-breakpoint-up(md) {
      text-align: center;
      margin: 0 auto;
      max-width: 80rem;
    }
  }
}

.homepage-previousmural{
  
    @extend .pb-5;
    position: relative;
    padding-bottom: 0rem !important;

    @include media-breakpoint-up(md) {
      padding-top: 10rem;
    padding-bottom: 10rem !important;
    position: relative;
    background-color: #151515;
    background: url(../../static/Home/pm-back.png);
    }

    .previous-mural-container{
      padding-right: 0px;
    padding-left: 0px;
      @include media-breakpoint-up(md) {
        padding-right: 15px;
    padding-left: 15px;
      }
    }
  
    .absolute-pixel--1 {
      top: 0;
      left: 0;
  
      @include media-breakpoint-up(md) {
        left: 4rem;
      }
  
      .pixel--1 {
        top: 0;
        left: 0;
        background-color: $color-primary-dark;
        @include media-breakpoint-up(md) {
          background-color: $color-accent;
          top: 4rem;
          left: 4rem;
        }
      }
  
      .pixel--2 {
        top: 2rem;
        left: 2rem;
        background-color: $color-accent;
        @include media-breakpoint-up(md) {
          background-color: $color-primary-dark;
        }
      }
  
      .pixel--3 {
        top: 0;
        left: 4rem;
        background-color: $color-primary-light;
        @include media-breakpoint-up(md) {
          background-color: $color-primary-light;
        }
      }
    }
  
    .absolute-pixel--2 {
      bottom: 0;
      right: 0;
  
      @include media-breakpoint-up(md) {
        right: 4rem;
      }
  
      .pixel--1 {
        bottom: 2rem;
        right: 0;
        background-color: $color-accent;
        @include media-breakpoint-up(md) {
          right: 2rem;
        }
      }
  
      .pixel--2 {
        bottom: 0;
        right: 2rem;
        background-color: $color-primary-dark;
        @include media-breakpoint-up(md) {
          right: 4rem;
        }
      }
  
      .pixel--3 {
        bottom: 0;
        right: 0;
        background-color: transparent;
        @include media-breakpoint-up(md) {
          background-color: $color-primary-light;
        }
      }
    }
  }




.homepage-whatis {
  
  padding-top: 3rem;
  padding-bottom: 0rem ;
  position: relative;
  background-color: #fff;

  @include media-breakpoint-up(md) {
    padding-top: 10rem;
  padding-bottom: 11rem !important;
  position: relative;
  background-color: #fff;
  }

  .absolute-pixel--1 {
    top: 0;
    left: 0;

    @include media-breakpoint-up(md) {
      left: 4rem;
      padding-top: 10rem;
  padding-bottom: 15rem !important;
  position: relative;
  background-color: #fff;
    }

    .pixel--1 {
      top: 0;
      left: 0;
      background-color: $color-primary-dark;
      @include media-breakpoint-up(md) {
        background-color: $color-accent;
        top: 4rem;
        left: 4rem;
      }
    }

    .pixel--2 {
      top: 2rem;
      left: 2rem;
      background-color: $color-accent;
      @include media-breakpoint-up(md) {
        background-color: $color-primary-dark;
      }
    }

    .pixel--3 {
      top: 0;
      left: 4rem;
      background-color: $color-primary-light;
      @include media-breakpoint-up(md) {
        background-color: $color-primary-light;
      }
    }
  }

  .absolute-pixel--2 {
    bottom: 0;
    right: 0;

    @include media-breakpoint-up(md) {
      right: 4rem;
    }

    .pixel--1 {
      bottom: 2rem;
      right: 0;
      background-color: $color-accent;
      @include media-breakpoint-up(md) {
        right: 2rem;
      }
    }

    .pixel--2 {
      bottom: 0;
      right: 2rem;
      background-color: $color-primary-dark;
      @include media-breakpoint-up(md) {
        right: 4rem;
      }
    }

    .pixel--3 {
      bottom: 0;
      right: 0;
      background-color: transparent;
      @include media-breakpoint-up(md) {
        background-color: $color-primary-light;
      }
    }
  }
}

.homepage-donate {
  @extend .pb-5;
  padding-top: 5rem;
  position: relative;
  background-color: #F6EFE5;

  @include media-breakpoint-up(md) {
    padding-top: 10rem;
  }


  .absolute-pixel--1 {
    top: 0;
    left: 0;

    @include media-breakpoint-up(md) {
      left: 4rem;
    }

    .pixel--1 {
      top: 0;
      left: 0;
      background-color: $color-primary-dark;
      @include media-breakpoint-up(md) {
        background-color: $color-accent;
        top: 4rem;
        left: 4rem;
      }
    }

    .pixel--2 {
      top: 2rem;
      left: 2rem;
      background-color: $color-accent;
      @include media-breakpoint-up(md) {
        background-color: $color-primary-dark;
      }
    }

    .pixel--3 {
      top: 0;
      left: 4rem;
      background-color: $color-primary-light;
      @include media-breakpoint-up(md) {
        background-color: $color-primary-light;
      }
    }
  }

  .absolute-pixel--2 {
    bottom: 0;
    right: 0;

    @include media-breakpoint-up(md) {
      right: 4rem;
    }

    .pixel--1 {
      bottom: 2rem;
      right: 0;
      background-color: $color-accent;
      @include media-breakpoint-up(md) {
        right: 2rem;
      }
    }

    .pixel--2 {
      bottom: 0;
      right: 2rem;
      background-color: $color-primary-dark;
      @include media-breakpoint-up(md) {
        right: 4rem;
      }
    }

    .pixel--3 {
      bottom: 0;
      right: 0;
      background-color: transparent;
      @include media-breakpoint-up(md) {
        background-color: $color-primary-light;
      }
    }
  }
}

.homepage-location {
  position: relative;

  .absolute-pixel--1 {
    top: 0;
    left: 0;
    z-index: 10;

    @include media-breakpoint-up(md) {
      left: 4rem;
    }

    .pixel--1 {
      top: 0;
      left: 0;
      background-color: $color-primary-dark;
      @include media-breakpoint-up(md) {
        background-color: $color-accent;
      }
    }

    .pixel--2 {
      top: 2rem;
      left: 2rem;
      background-color: $color-accent;
      @include media-breakpoint-up(md) {
        background-color: $color-bg-medium-gray;
      }
    }

  }

}


.homepage-streetview {
  &__image {
    width: 100%;
    height: 60rem;
    object-fit: cover;
    object-position: 50% 50%;
  }
}

.homepage-instagram {
  padding-top: 5rem;
  padding-bottom: 5rem;

  @include media-breakpoint-up(md) {
    padding-top: 10rem;
  padding-bottom: 10rem;
  }

  .header-text {
    max-width: 88rem;
    margin: 0 auto;
  }
}

.insta-header{
  font-style: 40px;
}

.back-sec-1-1{
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.back-sec-1-2{
  position: absolute;
  right: 0px;
  top: 0px;
}

.back-sec-2-1{
  position: absolute;
  top: 0px;
  left: 0px;
}

.back-sec-2-2{
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.back-sec-2{
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.back-sec-3-1{
  position: absolute;
  top: -1px;
  left: 0px;
}

.back-sec-3-2{
  position: absolute;
  right: 0px; 
  top: -1px;
}

.back-sec-3-3{
  position: absolute;
  bottom: -112px;
  left: 40%;
}

.back-sec-3-3-clone{
  position: absolute;
  bottom: -38px;
  left: 40%;
}

.back-sec-3-4-clone{
  position: absolute;
  left: 244px;
    top: 42px;
 

  

    

  
  
}

.what-is-mobile-back{
  position: absolute;
  top:0px;
  left: 0px;
}

.back-sec-m-3-1{
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.back-sec-m-3-2{
  position: absolute;
  top: 0px;
  right: 0px;
}

.back-sec-m-3-2-clone{
  position: absolute;
  top: 50px;
  left: 0px;
}

.back-sec-m-3-1-clone{
  position: absolute;
  top: -69px;
  right: 0px;
}

.back-sec-m-3-3{
  position: absolute;
  top: 30%;
  left: 0px;
}

.text-orange{
  font-size: 14px;
  font-weight: 400;
  color: #F96815;

  @include media-breakpoint-up(md) {
    font-size: 20px;
  font-weight: 400;
  color: #F96815;
  }
}

.understory-sec{
  padding-top: 0rem !important;
  background-color: #fff0 !important  ;
}


.back-sec-m-3-1-clone-home{
  position: absolute;
  right: 0px;
  bottom: -49px;
}


.homepage-story-card-1{
  padding-bottom: 20px !important;

  @include media-breakpoint-up(md) {
    padding-bottom: 2rem !important;
  }

}