@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// Fonts
@font-face {
  font-family: 'retrograde';
  src: url('../static/fonts/retrograde/retrograde-regular-webfont.woff2') format('woff2'),
  url('../static/fonts/retrograde/retrograde-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'retrograde';
  src: url('../static/fonts/retrograde/retrograde-bold-webfont.woff2') format('woff2'),
  url('../static/fonts/retrograde/retrograde-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;

}


@font-face {
  font-family: 'degular';
  src: url('../static/fonts/degular/DegularText-Thin.otf') format('opentype');
  font-weight: 200;
  font-style: l;

}

@font-face {
  font-family: 'degular';
  src: url('../static/fonts/degular/DegularText-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'degular';
  src: url('../static/fonts/degular/DegularText-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;

}

$serif-font: "Work Sans", sans-serif;;
$sansserif-font: 'Montserrat', sans-serif;
$timer-font: "Work Sans", sans-serif;
$worksans-font: "Work Sans", sans-serif;

$degular-font: "Degular";

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  font-size: 1.6rem;
  font-family: $serif-font;
}

h1 {
  font-family: $degular-font;
  font-size: 3.2rem;
  font-weight: 700;
  @include media-breakpoint-up(md) {
    font-size: 4.8rem;
  }
}

h2 {
  font-family: $degular-font;
  font-size: 2.4rem;
  font-weight: 700;
  @include media-breakpoint-up(md) {
    font-size: 4rem;
  }
}
h3 {
  font-family: $degular-font;
  font-size: 2rem;
}
h4 {
  font-family: $sansserif-font;
  font-size: 1.6rem;
  font-weight: 400;
  @include media-breakpoint-up(md) {
    font-size: 2rem;
  }
}

h5 {
  font-family: $serif-font;
  font-size: 1.4rem;
  font-weight: 400;
  @include media-breakpoint-up(md) {
    font-size: 2rem;
  }
}

h6 {
  font-family: $sansserif-font;
  font-size: 1.4rem;
  font-weight: 400;
  @include media-breakpoint-up(md) {
    font-size: 2rem;
  }
}

p {
  font-size: 1.4rem;
  font-weight: 300;
  @include media-breakpoint-up(md) {
    font-size: 2rem;
  }
}

.override-merriweather {
  font-family: $serif-font;
}

.override-monserrat {
  font-family: $sansserif-font;
}

.override-work-sans {
  font-family: $worksans-font;
}

.override-retrograde {
  font-family: $timer-font;
}

.override-retrograde--bold {
  font-family: $timer-font;
  font-weight: 700;
}

.link-light {
  color: $font-color-white;

  :hover {
    color: $font-color-secondary;
  }

  :active {
    @extend .color-accent;
  }

  :visited {
    @extend .color-accent;
  }
}

.pm-header{

  h2{

    font-family: $degular-font;
    font-size: 24px;
    font-weight: 700;
    position: relative;
    color: #000;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: 56px;
      text-align: left;
      padding-bottom: 25px;
    }
  }

  p{
    font-family: $worksans-font;
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      color: #000;
  
    @include media-breakpoint-up(md) {
      font-size: 20px;
      
      text-align: left;
      color: #000;
    }
  }
  


}


.color-white{

  h2{
    
  color: #fff;
  }

  p{
    color: #fff;
  }
}

.color-black{

  h2{
    
  color: #000;
  }

  p{
    color: #000;
  }
}

.text-center{
  p{
    text-align: center;
  }
}