.donate-hero {
  background-image: url("../../static/mobile-payment-banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 15rem;
  font-family: $sansserif-font;
  padding-top: 13rem;
  padding-bottom: 1rem;

  @include media-breakpoint-up(md) {
    min-height: 30rem;
    background-image: url("../../static/banner-donate-desktop.jpg");
  }

  span {
    font-size: 1.4rem;
  }

  strong {
    display: block;
    max-width: 24rem;
  }
}

.donate-component-submit {
  width: 100%;
  @include media-breakpoint-up(md) {
    width: auto;
  }
}

.plus-minus-accordion {
  font-family: $serif-font;
  font-size: 1.6rem;
  font-weight: 400;
  border-bottom: 1px solid grey;
  padding: 15px 0;

  &.active-true {
    .accordion-header {
      &:after {
        content: "-";
      }
    }
  }

  .accordion-header {
    position: relative;
    padding-left: 0;
    padding-right: 5rem;
    cursor: pointer;

    &:after {
      content: "+";
      display: block;
      position: absolute;
      right: 10px;
      top: 50%;
      line-height: 20px;
      margin-top: -10px;
    }
  }

  p {
    font-size: inherit;
  }
}

.donate-bg {
  background-color: $color-primary-light;

  @include media-breakpoint-up(md) {
    background-image: linear-gradient(90deg, $color-primary-light 50%, $color-primary-dark 50%);
  }
}

.donate-content-wrapper {
  padding: 0 1.5rem;

}

.donate-desktop-title {
  font-size: 3.2rem;
  max-width: 128rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  margin: 0 auto 3rem;
}

.donate-tabs {
  &__nav {
    @extend .pt-5;
    display: flex;

    @include media-breakpoint-up(md) {
      display: none;
    }

    &.step-2 {
      display: none;
    }

    &__item {
      flex-grow: 1;
      border-bottom: 1px solid $color-primary-offwhite;
      text-align: center;
      padding: 0.5rem;

      &.is-active {
        h5 {
          font-weight: 700;
        }

        border-color: $color-primary-dark;
      }
    }
  }

  &__content {
    @include media-breakpoint-up(md) {
      display: flex;
    }

    &__item {
      @extend .pb-5;
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }

      &.is-active {
        display: block;
      }

      &--form {
        @include media-breakpoint-up(md) {
          width: 66.66%;
          background-color: $color-primary-light;
          padding: 2rem;
        }
        @include media-breakpoint-up(lg) {
          padding: 8rem 2rem 4rem 6rem;
        }
        //@include media-breakpoint-up(xl) {
        //  width: calc(50% + 114px);
        //}
      }

      &--faq {
        @include media-breakpoint-up(md) {
          min-height: calc(100vh - 30rem);
          width: 33.33%;
          background-color: $color-primary-dark;
          color: $color-primary-light;
          padding: 2rem;
        }
        @include media-breakpoint-up(lg) {
          padding-top: 6rem;
        }
        //@include media-breakpoint-up(xl) {
        //  width: calc(50% - 114px);
        //}

        a,
        .span-link {
          color: $color-blue;
          text-decoration: underline;
          cursor: pointer;
        }

        //.container {
        //  @include media-breakpoint-up(xl) {
        //    max-width: calc(1140px * 0.4);
        //    margin: 0;
        //  }
        //}
      }
    }
  }
}

.faq-component--header {
  font-family: $sansserif-font;
  font-size: 3.2rem;
  font-weight: 700;
}

#pixelMomentDF {
  width: 1px;
  min-width: 100%;
  overflow: hidden;
  border: 0;
  vertical-align: top;
}