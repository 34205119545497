.donate-component {
  &.step-2 {
    padding-top: 2rem;
    @include media-breakpoint-up(md) {
      padding-top: 0;
    }
  }

  

  button {
    font-family: $sansserif-font;
    font-size: 1.6rem;
    font-weight: 400;
    @include media-breakpoint-up(md) {
      font-size: 2rem;
    }
  }

  @mixin page-initial {
    .step-0 & {
      @content;
    }
  }
  @mixin page-edit {
    .step-1 & {
      @content;
    }
  }
  @mixin page-confirm {
    .step-2 & {
      @content;
    }
  }

  &__step-title {
    
    padding: 1rem 0;
    margin-bottom: 2rem;
    font-weight: 500;
    font-size: 20px;
    line-height: 23.46px;
    font-family: $worksans-font;

    span {
      opacity: 0.5;
    }

    @include page-confirm {
      display: none;
    }
    @include page-edit {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  &__description {
    display: block;
    font-family: $worksans-font;
    font-size: 16px !important;
    line-height: 18.77px ;
    font-weight: 400 !important;
    color: #000;

    @include media-breakpoint-up(md) {
      display: block;
    }

    @include page-confirm {
      display: none;
    }

    @include page-edit {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  &__confirm-title {
    border-bottom: 1px solid $color-primary-dark;
    padding: 1rem 0;
    margin-bottom: 1rem;
    display: none;

    @include page-confirm {
      display: block;
    }
  }

  &__desktop-content--initial {
    display: block;
    margin-bottom: 2rem;

    @include page-initial {
      @include media-breakpoint-up(md) {
        display: block;
      }
    }

    h2 {
      font-size: 3.2rem;
    }
  }

  &__desktop-content--edit {
    display: none;
    margin-bottom: 2rem;
    border-bottom: 1px solid $color-primary-dark;

    @include page-edit {
      @include media-breakpoint-up(md) {
        display: block;
      }
    }

    h2 {
      font-size: 3.2rem;
      margin-bottom: 3rem;
    }
  }

  &__desktop-content--confirm {
    display: none;
    margin-bottom: 2rem;
    border-bottom: 1px solid $color-primary-dark;

    @include page-confirm {
      @include media-breakpoint-up(md) {
        display: block;
      }
    }

    h2 {
      font-size: 3.2rem;
      margin-top: 4rem;
      margin-bottom: 5rem;
    }

    button {
      float: right;
      padding: 0;
      border: 0;
      color: #0788FF;
      background: none;
      font-size: 2rem;
      font-weight: 700;
    }
  }

  &__field {
    margin-bottom: 1rem;

    label {
      font-family: $worksans-font;

      span {
        font-size: 14px;
        font-weight: 400;
        @include media-breakpoint-up(md) {
          font-size: 14px;
          margin-bottom: 1rem;
          font-weight: 400;
        }

        @include page-confirm {
          display: inline-block;
          width: 37%;
          @include media-breakpoint-up(md) {
            display: block;
            width: auto;
          }
        }
      }

      input[type="text"],
      input[type="email"] {
        width: 100%;
        background-color: #FFF ;
        border: 1px solid rgba(0, 0, 0, 0.2);
        padding: 1rem;
        font-family: $worksans-font;
        color: $font-color-secondary;

        outline:none  !important;

        &:focus{
          border: 1px solid rgba(0, 0, 0, 1) ;
        }

        @include media-breakpoint-up(md) {
          background-color: #FFF ;
          border: 1px solid rgba(0, 0, 0, 0.2);
          height: 6rem;
          padding: 1rem 2rem;
          font-size: 16px;
          font-weight: 400;
        }

        @include page-confirm {
          width: auto;
          background: none;
          padding: 0;

          @include media-breakpoint-up(md) {
            background-color: $color-primary-offwhite;
            border: 0;
            width: 100%;
            padding: 1rem 2rem;
          }
        }
      }
    }

    textarea {
      padding: 1rem;
      width: 100%;
      border: 0;
      resize: none;
      height: 16rem;
      font-family: $sansserif-font;
      color: $font-color-secondary;
      background-color: #FFF ;
        border: 1px solid rgba(0, 0, 0, 0.2);

      outline:none  !important;

        &:focus{
          border: 1px solid rgba(0, 0, 0, 1) ;
        }

      @include media-breakpoint-up(md) {
        background-color: #FFF ;
        
        height: 24.5rem;
      }
      @include page-confirm {
        @include media-breakpoint-up(md) {
          padding: 3rem;
          background-color: $color-primary-offwhite;
          border: 0;
        }
      }
    }

    .form-error {
      color: #EA2727;
      font-size: 14px !important;
      font-weight: 400 !important;
    }

    &--first-name {
      @include media-breakpoint-up(md) {
        
      }
    }

    &--last-name {
      @include media-breakpoint-up(md) {
        
      }
    }

    &--email {
      @include media-breakpoint-up(md) {
        
        width: 48%;
      }
    }

    &--image {
      display: block;
      justify-content: space-between;
      align-items: center;

      @include page-edit {
        @include media-breakpoint-up(md) {
          float: left;
          width: 55%;
        }
      }

      &.is-file-uploaded {
        display: flex;

        @include page-confirm {
          display: block;
          float: left;
          width: 35%;
        }
        @include page-edit {
          @include media-breakpoint-up(md) {
            display: block;
            float: left;
            width: 45%;
          }
        }
      }

      &__crop {
        width: 60%;

        @include page-edit {
          @include media-breakpoint-up(md) {
            width: auto;
          }
        }

        img {
          width: 100%;
          max-width: 100%;
          height: auto;
        }
      }

      &__preview {

        img {
          width: 100%;
          height: auto;
        }
      }

      &__input {
        @include page-confirm {
          display: none;
        }

        .is-file-uploaded & {
          width: 35%;

          @include page-edit {
            @include media-breakpoint-up(md) {
              width: auto;
            }
          }
        }

        input {
          width: 1px;
          height: 1px;
          overflow: hidden;
          display: block;
        }

        button {
          width: 100%;
          border: 0;
          padding: 1rem 2rem;
          background-color: $color-primary-offwhite;
          color: $color-primary-dark;
          display: block;

          @include media-breakpoint-up(md) {
            width: 35%;
            color: $color-primary-dark;
            background-color: $color-primary-offwhite;
          }

          .is-file-uploaded & {
            @include media-breakpoint-up(md) {
              width: 100%;
            }
          }

          @include page-edit {
            @include media-breakpoint-up(md) {
              color: $color-primary-offwhite;
              background-color: $color-primary-dark;
              width: 100%;
            }
          }
        }
      }

      .size-details {
        font-size: 1.4rem;
        font-family: $sansserif-font;
      }

      .reminder-terms-condition {
        font-size: 1.6rem;
        font-family: $sansserif-font;

        span {
          color: $color-blue;
          text-decoration: underline;
          cursor: pointer;
        }

        @include page-edit {
          display: none;
        }

        
      }
    }

    &--story {
      @include page-confirm {
        float: right;
        width: 63%;
      }
      @include page-edit {
        @include media-breakpoint-up(md) {
          float: right;
          width: 53%;
        }
      }

      span {
        user-select: none;
        opacity: 0.5;
        margin-top: -3.5rem;
        margin-right: 1.5rem;
        margin-bottom: 3.5rem;
      }
    }

    &--tos {
      display: block;
      clear: both;

      @include page-confirm {
        display: block;
      }

      label {
        max-width: 90%;
        vertical-align: top;
      }

      .span-link {
        font-size: inherit;
        display: inline !important;
        color: $color-blue;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  &__reminder {
    display: none;
    font-family: $sansserif-font;
    margin-bottom: 2rem;
    font-size: 1.6rem;

    @include page-confirm {
      display: block;
    }
    @include media-breakpoint-up(md) {
      padding: 1.5rem 0;
      border-top: 1px solid $color-primary-dark;
      margin-bottom: 5rem;
    }
  }

  &__submit-buttons {
    @extend .clearfix;
    clear: both;

    .btn {
      border-radius: 2rem;
      width: 100%;
      min-width: auto;

      @include media-breakpoint-up(md) {
        border-radius: 0;
        width: auto;
      }

      &--next {
        float: right;
        width: 19rem;
      }

      &--edit {
        width: 48%;
        float: left;

        @include media-breakpoint-up(md) {
          width: 19rem;
        }
      }

      &--confirm {
        width: 48%;
        float: right;

        @include media-breakpoint-up(md) {
          width: 19rem;
        }
      }
    }
  }
}

.processing-modal {
  font-size: 3rem;
  text-align: center;
}


hr{
  border-top: 2px solid #DDDDDD;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.reminder-terms-condition{
  font-family: $worksans-font;
  font-size: 16px !important;
  line-height: 18.77px;
  color: #F96815;
  font-weight: 400 !important;

  span{
    text-decoration: underline;
  }

}

.form-error-ip{
  background-color: #FFF0F0 !important;
  border: 1px solid rgba(255, 0, 0, 0.2) !important;
  color:#EA2727 !important;

  &::placeholder{
    color:#EA2727 ;
  }
}


.image-crop-parent{
  width: 100%;
  @include media-breakpoint-up(md) {
    width: 300px;
  }
}


.is-file-uploaded{

  .row{
    
    margin-left: 0px;

  }
  div{
    width: 100%;
    

    // .col-md-4{
    //   padding-right: 5px;
    //   padding-left: 5px;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   background-color: #c4c4c4;
    // }
    
    // .col-md-8{
    //   background-color: #F5F5F5;
    //   padding-left: 0px;
    // }

    .donate-component__field--image__input{

      width: 100%;
      display: flex;
      height: 100%;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      min-height: 117px;

      button{
        width: 100%;
        margin: 0px auto
      }

    }
  }

  @include media-breakpoint-up(md) {

    

    .row{
      border: 1px solid rgba(0, 0, 0, 0.2);
      margin-left: 0px;

    }

    div{
      width: 100%;
      

      .col-md-4{
        padding-right: 5px;
        padding-left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #c4c4c4;
      }
      
      .col-md-8{
        background-color: #F5F5F5;
        padding-left: 0px;
      }

      .donate-component__field--image__input{

        width: 100%;
        display: flex;
        height: 100%;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        min-height: 243px;

        button{
          width: 304px;
          margin: 0px auto
        }

      }
    }
  }

  
}

.form-error {
  color: #EA2727;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.upload-btn{
  margin-top: 19px
}

.btn-download-cust{
  margin-top:10px
}

.btn-download-mobile{

  margin-bottom: 25px;
  @include media-breakpoint-up(md) {
    margin-bottom: 0px;
  }
}