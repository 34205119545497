

.card-cust {
	background: #F6EFE5;
	position: relative;
	
  height: 100%;
	
}

.what-is-header-d{
  font-family: $worksans-font;
  padding: 0 71px;
    height: 71px;
    font-size: 20px;
    font-weight: 500;
}

.what-is-text-d{
  margin-bottom:29px;
  margin-top:29px;
  font-family: $worksans-font;
  padding: 0 21px;
  font-size: 16px;
  text-align: left !important;
  color: #000;
  font-weight: 400 !important;
  line-height: 21.65px;

}

.icon{
  height: 246px;

  img{
    max-height: 100%;
  }
}


.whatif-carousel-mobile {


  


  .whatif-carousel-mobile-body{

    @include media-breakpoint-up(md) {
      display: flex;
      flex-wrap: wrap;
    justify-content: space-around;
      
    }
    
  }

  @mixin details-active { // "Read Stories" button is clicked
    .stories-carousel-mobile.is-details-active & {
      @content;
    }
  }

  @mixin slide-active { // Current slide
    .BrainhubCarouselItem--active & {
      @content;
    }
  }

  @mixin details-slide-active { // Current slide after "Read Stories" button is clicked
    .stories-carousel-mobile.is-details-active .BrainhubCarouselItem--active & {
      @content;
    }
  }

  $root: &;
  $slider-height: 70vw;

  overflow: hidden;
  position: relative;

  &__inner {
    width: 180vw;
    @include details-active {
      width: 200%;
    }
  }

  &__slide {
    position: relative;
    padding: 1.5rem;
    width: 100%;
    background-color:#F6EFE5;
    margin-bottom: 10px;

    @include media-breakpoint-up(lg) {
      width: 49%;
    }

    &__title {
      position: absolute;
      z-index: 5;
      top: 1.5rem;
      left: 1.5rem;
      right: 1.5rem;
      text-align: center;
      height: $slider-height;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(#C4C4C4, 0.8);
      color: $color-primary-dark;

      @include details-active {
        display: none;
      }
    }

    &__image {
      // width: 100%;
      // object-fit: cover;
      // object-position: 50% 50%;

      // @include details-active {
      //   display: block;
      //   position: absolute;
      //   height: calc(100% - 3rem);
      //   width: calc(100% - 3rem);
      // }
    }

    &__button {
      background-color: $color-primary-dark;
      color: $color-primary-light;
      border: 0;
      padding: 0.5rem 1rem;
      display: block;
      text-align: center;
      width: 100%;
      font-family: $sansserif-font;

      @include details-active {
        display: none;
      }
    }

    &__details {
      display: none;
      background-color: rgba(#5F5F5F, 0.8);
      color: $color-primary-light;
      padding: 7rem 1.5rem;
      position: relative;
      opacity: 0;
      transition: 0.3s opacity;
      min-height: 55rem;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-family: $sansserif-font;
      text-align: center;

      @include details-active {
        display: flex;
        opacity: 1;
      }

      &__title {
        margin-bottom: 4rem;
      }

      &__close {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        z-index: 5;

        &__button {
          border: 0;
          padding: 1rem 2rem;
          background: none;
          font-size: 4rem;
          line-height: 1;
          color: $color-primary-light;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  &__dots {
    text-align: center;

    @include details-active {
      display: none;
    }

    &__item {
      display: inline-block;
      margin: 1rem;
      padding: 0;
      border: 0;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      background-color: $color-primary-light;

      &.is-active {
        background-color: $color-accent;
      }
    }
  }

  &__arrows {
    position: absolute;
    bottom: 1rem;
    left: 0;
    right: 0;
    text-align: center;
    display: none;

    @include details-active {
      display: block;
    }

    &__prev,
    &__next {
      border: 0;
      padding: 1rem 2rem;
      background: none;
      font-size: 4rem;
      line-height: 1;
      color: $color-primary-light;

      &:disabled {
        color: darken($color-primary-light, 70%);
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.what-if-title{
  margin: 15px 0 10px 0;
    padding: 0;
    font-size: 21px;
    font-weight: 600;
    text-align: center;
    color: #414141;
}

.homepage-whatif-card--header{
  margin-bottom: 3rem;
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 31.85px;
  position: relative;
    color: #000;

    @include media-breakpoint-up(md) {
      font-size: 56px;
    text-align: center;
    padding-bottom: 25px;
    }
}
.whatif-carousel-mobile__dots{
  padding-top: 3rem;
  text-align: center;

  .is-active{
   background-color: #414141; 
  }
}

.icon1{
  padding-top: 18px;
}

.icon2{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -30px;
  img{
    height: 45%;
  }
}


.icon3{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 31px;
  img{
    height: 54%;
    @include media-breakpoint-up(md) {
      height: 37%;
    }
  }
}


.carcousel-img-parent{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 242px;
  margin:20px 0px;
  @include media-breakpoint-up(lg) {
    margin:0px 
  }
}

.WhatIs3{
  
  width: auto !important;
    height: 175px !important;
    max-width: 100%;
    max-height: 100%;
  margin-left: -31px;
  @include media-breakpoint-up(lg) {
    width: 73% !important;
  height: auto;
  }
}

.WhatIs2{
  width: auto !important;
    height: 154px !important;
    max-width: 100%;
    max-height: 100%;
  @include media-breakpoint-up(lg) {
    width: 73% !important;
  height: auto;
  }
}
.WhatIs1{
  width: auto !important;
    height: 300px !important;
    max-width: 100%;
    max-height: 100%;
  @include media-breakpoint-up(lg) {
    width: 73% !important;
  height: auto;
  }
}