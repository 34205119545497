.about-mobile-header {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.about-header-offset {
  height: 15rem;
  background-color: $color-primary-dark;
}


#about-pixel-moments {
  position: relative;
  padding-top: 7rem;
  padding-bottom: 7rem;
  background-color: $color-primary-light;

  .absolute-pixel--1 {
    top: 0;
    left: 0;

    @include media-breakpoint-up(md) {
      left: 4rem;
    }

    .pixel--1 {
      top: 0;
      left: 0;
      background-color: $color-primary-dark;
      @include media-breakpoint-up(md) {
        background-color: $color-accent;
        top: 0;
        left: 0;
      }
    }

    .pixel--2 {
      top: 2rem;
      left: 2rem;
      background-color: $color-accent;
      @include media-breakpoint-up(md) {
        background-color: $color-primary-dark;
      }
    }

    .pixel--3 {
      top: 0;
      left: 4rem;
      @include media-breakpoint-up(md) {
        background-color: $color-primary-dark;
      }
    }
  }

  .absolute-pixel--2 {
    bottom: 0;
    right: 0;

    @include media-breakpoint-up(md) {
      right: 4rem;
    }

    .pixel--1 {
      bottom: 2rem;
      right: 0;
      background-color: $color-accent;
      @include media-breakpoint-up(md) {
        right: 4rem;
        bottom: 0;
      }
    }

    .pixel--2 {
      bottom: 0;
      right: 2rem;
      background-color: $color-primary-dark;
      @include media-breakpoint-up(md) {
        right: 2rem;
        bottom: 2rem;
      }
    }

    .pixel--3 {
      bottom: 0;
      right: 0;
      background-color: transparent;
      @include media-breakpoint-up(md) {
        background-color: $color-primary-dark;
        filter: brightness(1.75);
      }
    }
  }

}

.about-pixel-moments {
  @extend .flex-center;
  background-color: transparent;
}

.about-future-leaders {
  position: relative;
  background-color: #151515;
  padding: 2rem 1.5rem;

  @include media-breakpoint-up(md) {
    position: relative;
  background-color: #333333;
  padding: 7rem 0px;
  }

  .absolute-pixel--1 {
    top: 0;
    left: 0;

    @include media-breakpoint-up(md) {
      left: 4rem;
    }

    .pixel--1 {
      top: 0;
      left: 0;
      background-color: $color-accent;
      @include media-breakpoint-up(md) {
        top: 2rem;
        left: 2rem;
      }
    }

    .pixel--2 {
      top: 2rem;
      left: 2rem;
      background-color: $color-primary-dark;
      @include media-breakpoint-up(md) {
        top: 4rem;
        left: 4rem;
        background-color: $color-primary-light;
      }
    }

    .pixel--3 {
      top: 0;
      left: 4rem;
      @include media-breakpoint-up(md) {
        background-color: $color-primary-light;
      }
    }
  }

  .absolute-pixel--2 {
    bottom: 0;
    right: 0;

    @include media-breakpoint-up(md) {
      right: 4rem;
    }

    .pixel--1 {
      bottom: 2rem;
      right: 0;
      @include media-breakpoint-up(md) {
        bottom: 0;
        right: 4rem;
        background-color: $color-accent;
      }
    }

    .pixel--2 {
      bottom: 0;
      right: 2rem;
      @include media-breakpoint-up(md) {
        background-color: $color-primary-light;
        bottom: 2rem;
      }
    }

    .pixel--3 {
      bottom: 0;
      right: 0;
      @include media-breakpoint-up(md) {
        background-color: $color-primary-light;
      }
    }
  }

  .future-leaders {
    padding: 7rem 1.5rem;
    color: $font-color-primary;

    @include media-breakpoint-up(md) {
      padding: 12rem 1.5rem;
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: auto;
      grid-template-areas:
            "title  image"
            "text  image"
            "button  .";
      column-gap: 2rem;
    }

    &__title {
      text-align: center;
      margin-bottom: 3rem;
      grid-area: title;

      @include media-breakpoint-up(md) {
        text-align: left;
      }
    }

    &__image {
      grid-area: image;
      padding: 0 3rem 2rem;
      @include media-breakpoint-up(md) {
        padding: 0;
      }
    }

    &__text {
      grid-area: text;
    }

    &__button {
      grid-area: button;
      text-align: center;
        padding-top: 4rem;


      .btn {
        width: 100%;
        @include media-breakpoint-up(lg) {
          width: 50%;
        }
      }
    }
  }
}


.component-about-hero {

  display: grid;
  grid-template-columns: 100% !important;
  grid-template-rows: auto;
  padding: 0px !important;
  grid-template-areas: 
                       "head "
                       "text "
                       "btn ";

  @include media-breakpoint-up(md) {
    display: grid;
  grid-template-columns: 80% 20% !important;
  grid-template-rows: auto;
  padding: 0px !important;
  grid-template-areas: 
                       "head image"
                       "text image"
                       "btn image";
  }

  .component-about-hero-heading {
    @extend .px-3;
    @extend .pb-3;
    font-weight: 700;
    font-size: 32px;
    line-height: 42.46px;
    font-family: $degular-font;
    grid-area: head;
    color: black;
    text-align: center;
    width: 100%;
    color: black;
    padding: 70px 0px 10px 0px    !important;
    

    @include media-breakpoint-up(md) {
      
      font-weight: 700;
      font-size: 80px;
      grid-area: head;
      color: black;
      text-align: left;
      width: 100%;
      color: black;
      padding: 70px 38px 10px 58px    !important;
      line-height: 106.16px;
    }

    span{
      color: #F96815
    }
  }

  

  .component-about-hero-text {
    @extend .px-3;

    

    grid-area: text;
    text-align: left;
    justify-self: center;
    align-self: center;
    line-height: 21.65px;
    padding: 10px 0px 10px 0px !important;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    text-align: center;
    
    @include media-breakpoint-up(md) {
      
      grid-area: text;

    text-align: left;
    justify-self: start;
    align-self: start;
    line-height: 1.25;
    padding: 10px 38px 10px 58px    !important;
    font-size: 24px;
    font-weight: 400;
    color: #000;
    }

    p{
      font-size: 2.0rem;
    padding: 50px 40px;
    color: black
    }

  }

  .component-about-hero-btn {
    @extend .px-3;

    grid-area: btn;

    text-align: center;
    justify-self: start;
    align-self: start;
    line-height: 1.25;
    padding: 10px 0px 10px 0px    !important;
    width: 100%;
    
    @include media-breakpoint-up(md) {
      
      grid-area: btn;

    text-align: left;
    justify-self: start;
    align-self: start;
    line-height: 1.25;
    padding: 10px 38px 10px 58px    !important;
    }

    p{
      font-size: 2.0rem;
    padding: 50px 40px;
    color: white
    }

  }

  
}


.component-future-leader {

  background-color: #000000ba;
  
  display: grid;
  grid-template-columns: 100% !important;
  grid-template-rows: auto;
  padding: 0px !important;
  grid-template-areas: "head "
                       "nav "
                       "main";
                       

  @include media-breakpoint-up(lg) {
    display: grid;
    background-color: #000000ba;
  border: 3px solid white ;
  display: grid;
  grid-template-columns: 50% 50% !important;
  grid-template-rows: auto;
  padding: 0px !important;
    grid-template-areas: "head head"
                       "nav  main";
  }

  .compenent-future-leader-heading {
    @extend .px-3;
    @extend .pb-3;
    font-weight: 600;
    grid-area: head;
    color: black;
    justify-self: start;
    align-self: end;
    width: 100%;
    color: white;
    padding: 30px 38px     !important;
    text-align: center;
    

    @include media-breakpoint-up(md) {
      justify-self: start;
      text-align: left
    }
  }

  .component-future-leader-image  {
    @extend .flex-center;

    
    grid-area: nav;
    

    

    @include media-breakpoint-up(lg) {
      border-top:3px solid white;
    border-right: 3px solid white;
    }
  }

  .component-future-leader-text {
    

    grid-area: main;

    text-align: left;
    justify-self: start;
    align-self: start;
    
    
    height: 100%;
    padding: 24px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-up(lg) {
      
     
      border-top:3px solid white;
    }

    

    p{
      font-size: 2.0rem;
    
    color: white;

    @include media-breakpoint-up(md) {
      
     
      font-size: 20px;
      line-height: 26px;
      font-weight: 400;
      padding: 40px !important;
    }

    }
    
    

  }

  
}


.pm-22{
  padding: 64px 20px;
  @include media-breakpoint-up(md) {
    padding: 94px 83px;
  }
}

.pm2021{
  background-color: #F6EFE5;
  padding: 4rem 0px !important;
  position: relative;

  @include media-breakpoint-up(md) {
    
    padding: 10rem 0px !important;     
  }
}

.pm2021-g1 {

  background-color: #fff0;
  
  display: grid;
  grid-template-columns: 100% !important;
  grid-template-rows: auto;
  padding: 0px !important;
  grid-template-areas: 
                       "nav"
                       "main";
                       

  @include media-breakpoint-up(lg) {
    background-color: #fff0;
    border: 3px solid black ;
    display: grid;
    margin-top: 50px;
    grid-template-columns: 56% 44% !important;
    grid-template-rows: auto;
    padding: 0px !important;
    grid-template-areas: 
                       "nav  main";
              
  }

  

  .pm2021-g1-image  {
    @extend .flex-center;

    
    grid-area: nav;
    
    iframe{
      border: none;
      width: 371px;
      height: 200px;
      @include media-breakpoint-up(md) {
        width: 736px;
        height: 480px;
      }
    }

    @include media-breakpoint-up(md) {
      border-right: 3px solid black;
    }
  }

  .pm2021-g1-text {
    @extend .px-3;
    padding: 40px 20px !important;
    grid-area: main;

    text-align: left;
    justify-self: start;
    align-self: start;
    line-height: 1.25;
    @include media-breakpoint-up(md) {
      padding: 50px 40px !important;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      
    }

    h3{
      font-family: $degular-font;
      margin-bottom: 15px;
      font-weight: 700;
      font-size: 20px;

      @include media-breakpoint-up(md) {
        font-size: 36px;
        
      }

    }
    p{
      font-family: $worksans-font;
      margin-bottom: 15px;
      font-weight: 400;
      font-size: 16px;
    
    
    }
    

  }

  
}

.pm2021-g2 {

  background-color: #fff0;
  
  border-top:none;
  display: grid;
  grid-template-columns: 100%  !important;
  grid-template-rows: auto;
  padding: 0px !important;
  grid-template-areas: 
  "nav"
                       "main ";
                       

  @include media-breakpoint-up(lg) {
    background-color: #fff0;
  border: 3px solid black ;
  border-top:none;
  display: grid;
  grid-template-columns: 44% 56%  !important;
  grid-template-rows: auto;
  padding: 0px !important;
  grid-template-areas: 
                       "main nav";
  }

  



  .pm2021-g2-image  {
    @extend .flex-center;

    
    grid-area: nav;
    
    iframe{
      border: none;
      width: 371px;
      height: 200px;
      @include media-breakpoint-up(md) {
        width: 736px;
        height: 480px;
      }
    }

    @include media-breakpoint-up(md) {
      border-left: 3px solid black;
    }
  }

  .pm2021-g2-text {
    @extend .px-3;
    padding: 50px 20px !important;
    grid-area: main;

    text-align: left;
    justify-self: start;
    align-self: start;
    line-height: 1.25;
    @include media-breakpoint-up(md) {
      padding: 50px 40px !important;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      
    }

    h3{
      font-family: $degular-font;
      margin-bottom: 15px;
      font-weight: 700;
      font-size: 20px;

      @include media-breakpoint-up(md) {
        font-size: 36px;
        
      }

    }
    p{
      font-family: $worksans-font;
      margin-bottom: 15px;
      font-weight: 400;
      font-size: 16px;
    
    
    }
    a{
      margin-top:10px !important;
      display: block;
      width: 105px;
    }

  }

  
}

.pm-22-image{
  img{
    
    padding: 25px 0px ;

    @include media-breakpoint-up(md) {
      
      padding: 59px 0px ;
      
    }
  }
  
}

.hero-about{
  
  flex-direction: row !important;
  align-items: center;
  padding: 7rem 2rem !important;
  background-color: transparent !important;
}

#about-pixel-moments{
  padding: 0px !important;
  background-color: white !important;
}

.back-sec-1-1{
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.back-sec-1-2{
  position: absolute;
  right: 0px;
  top: 0px;
}


.back-sec-about-r{
  position: absolute;
  bottom: -34px;
  right: 0px;

  img{
    height: 0%;
    height: 400px;
    

    
    

    @include media-breakpoint-up(md) {
      height: 526px;
    }

  }
}

.back-sec-about-l{
  position: absolute;
  bottom: 20%;
  left: 0px;


}


.fl-top{
  position: absolute;
  top: -1px;
  left: 0px
}
.fl-btm{
  position: absolute;
  bottom: -74px;
  left: 40%
}

.pm-22-parent{
  position: relative;
}


.pm-23-parent{
  background: rgba(246, 239, 229, 1)  ;
  padding:150px 0;
}


.pm22-top{
  position: absolute;
  top: 0px;
  left: 0px
}

.pm22-btm{
  position: absolute;
  top: 0px;
  left: 0px
}

.pm22-about-top{
  position: absolute;
  top: 0px;
  right: 0px
}

.pm22-about-btm{
  position: absolute;
  bottom: -328px;
  left: 0px
}

.pm22-about-top-m{
  position: absolute;
  top: 0px;
  left: -19px
}

.pm22-about-btm-m{
  position: absolute;
  bottom: 0px;
  right: 0px
}

.pm-2022-text{
  p{

  
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 135.3%;
    text-align: left;
  color: #000;

  @include media-breakpoint-up(md) {
    font-family: Work Sans;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 135.3%;
text-align: center;
  color: #000;
  }

  }
}


.pm-2023-text{
  p{

  
    font-weight: 500;
    font-size: 16px;
    line-height: 158.8%;
  color: #000;

  @include media-breakpoint-up(md) {
    font-size: 20px;
  line-height: 31.76px;
  font-weight: 500;
  color: #000;
  }

  }
}

.back-sec-m-1-1{
  position: absolute;
  right: 0px;
  top: 85px;
}

.back-sec-m-1-2{
  position: absolute;
  bottom: -8px;
  left: 0px;
}

.about-custom-1{
  padding: 0px 50px;

  @include media-breakpoint-up(md) {
    padding: 0px 0px;
  }
}


.user-desig{
  p{
    margin:0px;
    font-family: Work Sans;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 16.24px;
color: #585858;

    @include media-breakpoint-up(md) {
      font-family: Work Sans;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 135.3%;
color: #585858;

    }
  }
}


.user-photo{
  display: flex;
  justify-content: center;
  align-items: center;
}


.about-winter-arts {
  border:1px solid black;

  &-1 {
    position:relative;

    p {
      position: absolute;
      top:1rem;
      left:1.5rem;
      font-size:14px;
      z-index: 5;
      color:white;
      margin:0;
    }
    img {
      width:100%;
      height:auto;
    }
  }
  &-row {
    display:flex;
  }
  &-2 {
    position:relative;
    text-align: center;
    flex:2;
    padding:2rem;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
      font-family: $degular-font;
      font-size: 14px;
      font-weight: 700;

      @include media-breakpoint-up(lg) {
        font-size: 56px;
      }
    }
  }
  &-3 {
    position:relative;
    flex: 3;

    p {
      position: absolute;
      bottom:1rem;
      left:1.5rem;
      font-size:14px;
      z-index: 5;
      color:white;
      margin:0;
    }
    img {
      width:100%;
      height:auto;
    }
  }
}
