.basic-page {
  &--hero {
    @extend .hero;
    min-height: 10rem;
    @include media-breakpoint-up(md) {
      min-height: 14rem;
    }
  }

  &--content {
    position: relative;
    padding-top: 20rem;
    padding-bottom: 5rem;
    // background-image: url("../../static/pixel-transition@2x.svg");
    // background-repeat: repeat-x;

    @include media-breakpoint-up(md) {
      min-height: 80rem;
    }

    &:before {
      background-image: linear-gradient($color-primary-dark, transparent);
      display: block;
      position: absolute;
      height: 10rem;
      content: "";
      top: 0;
      right: 0;
      left: 0;
    }

    h1 {
      font-size: 2.4rem;
      font-family: $sansserif-font;
      font-weight: 700;

      @include media-breakpoint-up(md) {
        font-size: 4rem;
      }
    }

    h2 {
      font-size: 2rem;
      padding-top: 3rem;

      @include media-breakpoint-up(md) {
        font-size: 3rem;
      }
    }

    ul, ol {
      font-size: 1.4rem;
      font-weight: 300;
      margin-bottom: 2rem;

      @include media-breakpoint-up(md) {
        font-size: 2rem;
      }
    }

    p {
      margin-bottom: 2rem;
      font-size: 1.4rem;
      font-weight: 300;

      @include media-breakpoint-up(md) {
        font-size: 2rem;
      }
    }

    .container {
      max-width: 85rem;
    }
  }
}