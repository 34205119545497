
.component-header {
  $header-breakpoint: lg;

  @extend .clearfix;

  //border-top: 4rem solid transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: background-color 0.3s;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: #151515;
 

  

  @include media-breakpoint-up($header-breakpoint) {
    border-top-width: 6.5rem;
    transition: background-color 0.3s;
  }

  &.is-scroll-active {
    position: fixed;
    border-top-width: 0;
    background-color: #151515;
  }

  &.is-menu-active {
    position: fixed;
    border-top-width: 4rem;
    background-color: $color-primary-dark;
    bottom: 0;
    height: 100vh;
    

    @include media-breakpoint-up($header-breakpoint) {
      bottom: auto;
      border-top-width: 6.5rem;
      background-color: transparent;
      position: absolute;
    }

    &.is-scroll-active {
      @include media-breakpoint-up($header-breakpoint) {
        border-top-width: 0;
        background-color: $color-primary-dark;
        position: fixed;
      }
    }
  }

  &__logo {
    text-align: left;

    a{
      img{

        padding-left: 17px;
        @include media-breakpoint-up(lg) {
          padding-left: 0px;
        }
      }
    }
    


    @include media-breakpoint-up($header-breakpoint) {
      float: left;
    }

    &__default {
      width: 15rem;
    }
  }

  &__hamburger {
    position: absolute;
    top: 3.4rem;
    right: 3rem;

    @include media-breakpoint-up($header-breakpoint) {
      display: none;
    }

    &__icon {
      @extend .far;
      @extend .fa-bars;

      border: 0;
      padding: 0;
      background: none;
      color: $color-primary-light;
      font-size: 3rem;

      &:focus {
        outline: none;
      }

      .is-light-header & {
        color: $color-primary-dark;
      }

      .is-menu-active & {
        @extend .fa-times;

        color: $color-primary-light;
      }
    }
  }

  &__menu {
    $root: &;

    display: none;
    height: calc(100vh - 14rem);
    justify-content: center;
    align-items: center;
    text-align: center;

    position: fixed;
    left: 50%;
    top: 50%;

    
    transform: translate(-50%, -50%);


    @include media-breakpoint-up($header-breakpoint) {
      display: block;
      height: auto;
      float: right;
      position: relative;
      left: 0%;
      top: 0%;
  
      
      transform: translate(0%, 0%);
    }

    .is-menu-active & {
      display: flex;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      @include media-breakpoint-up($header-breakpoint) {
        display: flex;
      }

      li {
        margin: 0;
        padding: 0;

        font-family: $degular-font;
        font-weight: 700;
        font-size: 32px;
        line-height: 42.46px;

        &.is-active {
          a {
            text-decoration: underline;
            color: #98BDFA;
          }
        }

        &#{$root}__home {
          @include media-breakpoint-up($header-breakpoint) {
            display: none;
          }
        }

        &#{$root}__donate {
          margin-top: 2rem;
          padding-top: 2rem;

          @include media-breakpoint-up($header-breakpoint) {
            border: 0;
            margin: 0;
            padding: 0;
          }

          a {
            color: $color-accent;
            position: relative;
            text-decoration: none;

            @include media-breakpoint-up($header-breakpoint) {
              background-color: #98BDFA;
              color: $color-primary-dark;
              font-weight: 700;
              text-decoration: none;
              border:2px solid #507bf300 !important;

              &:hover{
                background-color: #507CF3 !important;
                color: #fff !important;
                font-weight: 700 !important;
                border:2px solid #507CF3 !important;
                border-radius: 0px !important;
                padding: 1rem 3rem !important;
                text-decoration: none !important;
              }
          
              &:focus{
                background-color: #98BDFA !important;
                color: #000 !important;
                font-weight: 700 !important;
                border:2px solid #507CF3 !important;
                border-radius: 0px !important;
                padding: 1rem 3rem !important;
                box-shadow: none;
              }
              
              
            }

            &:hover {
              text-decoration: underline;
            }
          }
        }

        a {
          color: $color-primary-light;
          display: block;
          padding: 1.5rem;
          font-size: 3rem;

          @include media-breakpoint-up($header-breakpoint) {
            font-size: 20px;
            padding: 0.5rem 2rem;
            font-family: $worksans-font;
            font-weight: 500;
          }
          @include media-breakpoint-up(lg) {
            padding: 1rem 3rem;
          }
        }
      }
    }
  }
}


.light-header {
  $header-breakpoint: lg;

  @extend .clearfix;

  //border-top: 4rem solid transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: background-color 0.3s;
  padding: 16px 0;
  background-color: none;

  @include media-breakpoint-up($header-breakpoint) {
    border-top-width: 6.5rem;
    transition: background-color 0.3s;
  }

  &.is-scroll-active {
    position: fixed;
    border-top-width: 0;
    background-color: #151515;
  }

  &.is-menu-active {
    position: fixed;
    border-top-width: 4rem;
    background-color: $color-primary-dark;
    bottom: 0;

    

    @include media-breakpoint-up($header-breakpoint) {
      bottom: auto;
      border-top-width: 6.5rem;
      background-color: transparent;
      position: absolute;
    }

    &.is-scroll-active {
      @include media-breakpoint-up($header-breakpoint) {
        border-top-width: 0;
        background-color: $color-primary-dark;
        position: fixed;
        
      }
    }
  }

  &__logo {
    text-align: left;

    @include media-breakpoint-up($header-breakpoint) {
      float: left;
    }

    &__default {
      width: 15rem;
    }
  }

  &__hamburger {
    position: absolute;
    top: 3.4rem;
    right: 2rem;

    @include media-breakpoint-up($header-breakpoint) {
      display: none;
    }

    &__icon {
      @extend .far;
      @extend .fa-bars;

      border: 0;
      padding: 0;
      background: none;
      color: $color-primary-light;
      font-size: 3rem;

      &:focus {
        outline: none;
      }

      .is-light-header & {
        color: $color-primary-dark;
      }

      .is-menu-active & {
        @extend .fa-times;

        color: $color-primary-light;
      }
    }
  }

  &__menu {
    $root: &;

    display: none;
    height: calc(100vh - 14rem);
    justify-content: center;
    align-items: center;
    text-align: center;

    position: fixed;
    left: 50%;
    top: 50%;

    
    transform: translate(-50%, -50%);

    @include media-breakpoint-up($header-breakpoint) {
      display: block;
      height: auto;
      float: right;

      position: relative;
      left: 0%;
      top: 0%;
  
      
      transform: translate(0%, 0%);
    }

    .is-menu-active & {
      display: flex;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      @include media-breakpoint-up($header-breakpoint) {
        display: flex;
      }

      li {
        margin: 0;
        padding: 0;

        font-family: $degular-font;
        font-weight: 700;
        font-size: 32px;
        line-height: 42.46px;
        

        &.is-active {
          a {
            text-decoration: underline;
            color: #98BDFA;
          }
        }

        &#{$root}__home {
          @include media-breakpoint-up($header-breakpoint) {
            display: none;
          }
        }

        &#{$root}__donate {
          margin-top: 2rem;
          padding-top: 2rem;

          @include media-breakpoint-up($header-breakpoint) {
            border: 0;
            margin: 0;
            padding: 0;
          }

          a {
            color: $color-accent;
            position: relative;
            text-decoration: none;

            @include media-breakpoint-up($header-breakpoint) {
              background-color: #98BDFA;
              color: $color-primary-dark;
              font-weight: 700;
              text-decoration: none;
              border:2px solid #507bf300 !important;

              &:hover{
                background-color: #507CF3 !important;
                color: #fff !important;
                font-weight: 700 !important;
                border:2px solid #507CF3 !important;
                border-radius: 0px !important;
                padding: 1rem 3rem !important;
                text-decoration: none !important;
              }
          
              &:focus{
                background-color: #98BDFA !important;
                color: #000 !important;
                font-weight: 700 !important;
                border:2px solid #507CF3 !important;
                border-radius: 0px !important;
                padding: 1rem 3rem !important;
                box-shadow: none;
              }
              
              
            }
            &:hover {
              text-decoration: underline;
            }
          }
        }

        a {
          color: $color-primary-dark;
          display: block;
          padding: 1.5rem;
          font-size: 3rem;

          

          @include media-breakpoint-up($header-breakpoint) {
            font-size: 20px;
            padding: 0.5rem 2rem;
            font-family: $worksans-font;
            font-weight: 500;
          }
          @include media-breakpoint-up(lg) {
            padding: 1rem 3rem;
            
          }
        }
      }
    }
  }
}

.light-header ul li a {
  color: white;
  
  @include media-breakpoint-up(lg) {
    color: $color-primary-dark;
    
       
  }
}

.is-scroll-active ul li a {
  color: white;
  
  @include media-breakpoint-up(lg) {
    color: white;
  }
}

.light-header.is-menu-active .light-header__hamburger__icon {
  
  color: #fff;
}

.light-header .light-header__hamburger__icon {
  
  color: $color-primary-dark;
}

.light-header.is-scroll-active .light-header__hamburger__icon {
  
  color: #fff
}

.show-shape{
  display: block;
  z-index: -2;
}


.hide-shape{
  display: none !important;
}


.nav-b{
  position: fixed;
  bottom: 0px;
  left:0px
}

.nav-l{
  position: fixed;
  top: 209px;
  left: 0px;
}

.nav-r{
  position: fixed;
  top: 0px;
  right: 0px;
}


.social-parent{
  a{
    color: white;
    margin: 0px 15px;
    font-size: 3.2rem;
  }
  
}


.nav-i{
  position: fixed;
  bottom: 39px;
  width: 100%;
}

.header-parent{

  @include media-breakpoint-up(lg) {
    display: flex;
  align-items: center;
  justify-content: space-between;
  }
  
}

.btn-nav-cust{
  width: 212px;
}

.btn-nav-cust:hover{
  text-decoration: none !important;
}