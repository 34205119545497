.hero {
  position: relative;
  overflow: hidden;
  background-color: #000;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 2;
    transform: translateX(-50%) translateY(-50%);
  }

  &__img {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }

  &__overlay {
    position: relative;
    z-index: 5;
    padding: 15rem 2rem 0rem;
    min-height: 95vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-up(md) {
      padding: 15rem 2rem 0;
      min-height: 100vh;
      ;
    }

    &__text{
      font-size: 16px;
    line-height: 21.65px;
    font-weight: 400;
      max-width:760px;
      margin: 0 auto;

    @include media-breakpoint-up(md) {
      font-size: 24px;
    line-height: 34px;
    font-weight: 400;
    }
    }

    &__tagline {
      
      font-size: 32px;
      line-height: 42.46px;
      color: $color-white;
      font-family: $degular-font;
      font-weight: 700;

      @include media-breakpoint-up(md) {
        font-size: 80px;
        font-weight: 700;
        font-family: $degular-font;
        line-height: 116.16px;
      }
    }

    &__tagline-sub {
      font-size: 32px;
      line-height: 42.46px;
      color: $color-white;
      font-family: $degular-font;
      font-weight: 700;

      @include media-breakpoint-up(md) {
        font-size: 48px;
        font-weight: 700;
        font-family: $degular-font;
        line-height: 69.7px;
      }
    }

    &__cta {
      width: 100%;

      &:hover {
        text-transform: uppercase;
        transform: scale(1.05);
      }

      @include media-breakpoint-up(md) {
        width: 40rem;
      }
    }

    &__chevron {
      display: none;
      @include media-breakpoint-up(md) {
        display: block;
        position: absolute;
        width: 100%;
        left: 0;
      }
    }
  }
}


.hero-home{
  background-image: url("../../static/homepage-hero.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.home-overlay{
  background-color: rgba(0, 0, 0, 0.7);
}