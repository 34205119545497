// Base colors:
$color-accent: #ef3340;
$color-primary-dark: #000;
$color-primary-light: #f8f8f8;
$color-secondary: #ffce4f;
$color-optional: #71dbd4;
$color-primary-offwhite: #ece9e9;
$color-white: #ffffff;
$color-blue: #0788FF;

$font-color-primary: #000;
$font-color-secondary: #3f3f3f;
$font-color-white: #fff;

// Social media button colors:
$color-twitter: #1DA1F2;
$color-facebook: #4267B2;
$color-mail: #FCC129;
$color-instagram: #DD2A7B;


.color-accent {
  color: $color-accent;
}

// generic background colors:
$color-bg-medium-gray: #E5E5E5;
$color-bg-dark-gray: #272727;
