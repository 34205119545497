
.component-footer {
  @extend .py-5;
  background-color:#151515;
  color: $color-primary-light;

  @include media-breakpoint-up(md) {
      padding-left: 7rem;
      padding-right: 7rem;
  }

  .footer-grid {

    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas:
    "logo1"
    "msg-1"
    "social"
    "nav"
    "msg-2";
  
    @include media-breakpoint-up(lg) {
      display: grid;
      grid-template-columns: 10%;
      grid-template-rows: auto;
      text-align: center;
      grid-template-areas:
              
              "logo1  logo1 logo1 logo1 msg-1 msg-1 msg-1 msg-1 msg-1 msg-1"
              "logo1 logo1 logo1 logo1 msg-2 msg-2 msg-2 msg-2 msg-2 msg-2"
              "nav nav nav nav nav nav nav  social social social" ;
    }
  
  
  
    .footer-logo1 {
      @extend .px-3;
      @extend .pb-3;
      @extend .pt-3;
      grid-area: logo1;
      display: flex;
      justify-content: center;
  

      
      img{
        width: 293px;
        margin-bottom: 21px;
        @include media-breakpoint-up(lg) {
          width: 335px;
          margin-bottom: 0px;
        }

      }

      span{
        width: 6px;
            /* height: 100%; */
        background-color:  #fff;
        display: block;
        margin: 0px 22px;
  
        @include media-breakpoint-up(lg) {
          height: 100px
        }
      }
  
      @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: flex-start
      }
    }
  
    
    .footer-msg-1 {
      @extend .px-3;
      @extend .pb-3;
      @extend .pt-3;
      grid-area: msg-1;
      
      display: flex;
      justify-content: center;
  
      @include media-breakpoint-up(lg) {
        padding-bottom: 0px !important;
      display: flex;
      align-items: end;
      justify-content: flex-start
        
      }
    }
    .footer-msg-2 {
      @extend .px-3;
      @extend .pb-3;
      @extend .pt-3;
      grid-area: msg-2;
      display: flex;
      justify-content: center;
  
      @include media-breakpoint-up(lg) {
        padding-top: 0px !important;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start
      }
    }
    .footer-nav {
      @extend .px-3;
      @extend .pb-3;
      @extend .pt-3;
      grid-area: nav;
  
  
      @include media-breakpoint-up(lg) {
        
        display: flex;
        align-items: center;
      }
      
      ul{
        list-style: none;
        display: flex;
        padding: 0px;
        flex-wrap: wrap;
        justify-content: center;
        @include media-breakpoint-up(lg) {
          margin-bottom: 0px ;
          
        }
  
        li{
  
          &:first-child{
            a{
              margin-left: 0px;
            }
          }
          a{
            font-size: 16px;
            color: #fff;
            margin:10px
          }
        }
        
      }
  
      @include media-breakpoint-up(md) {
        
      }
    }
    .footer-social {
      @extend .px-3;
      
      grid-area: social;
      padding-top: 0px;
      padding-bottom: 0px;
      div{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 75%;
        a{
          color: white;
          font-size: 3.5rem;

        }
      }
  
      @include media-breakpoint-up(lg) {
        padding-top: 1rem;
        padding-bottom: 1rem;
        div{
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          width: 100%;
          a{
            color: white;
            font-size: 2.5rem;
          }
        }
      }
    }
  
    
  }
  
  .component-footer-header {
  }

  .component-footer-logo {
    margin: 0 auto;
    max-width: 10rem;
  }

  .component-footer-social {
    a {
      color: $font-color-white;
      display: inline-block;
      margin: 0 2rem;
      transition: color 0.5s ease;

      &:hover {
        color: $font-color-white;
      }
    }

  }

  .link-footer {
    color: $color-primary-light;
    font-family: $sansserif-font;
    text-decoration: none;
    display: block;
    padding: 1rem;

    @include media-breakpoint-up(md) {
      display: inline-block;
      padding: 1rem 2rem;
    }

    &:hover {
      color: $color-accent;
      text-decoration: underline;
    }
  }

  .component-footer-copyright {
    text-align: center;
    color: #A5A5A5;
    padding: 1rem;
    font-family: $sansserif-font;
  }
}


.light-footer {
  @extend .py-5;
  background-color: $color-primary-light;
  color: #000;

  @include media-breakpoint-up(md) {
      padding-left: 7rem;
      padding-right: 7rem;
  }

  .footer-grid {

    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas:
    "logo1"
    "msg-1"
    "social"
    "nav"
    "msg-2";
  
    @include media-breakpoint-up(lg) {
      display: grid;
      grid-template-columns: 10%;
      grid-template-rows: auto;
      text-align: center;
      grid-template-areas:
              
              "logo1  logo1 logo1 logo1 msg-1 msg-1 msg-1 msg-1 msg-1 msg-1"
              "logo1 logo1 logo1 logo1 msg-2 msg-2 msg-2 msg-2 msg-2 msg-2"
              "nav nav nav nav nav nav nav  social social social" ;
    }
  
  
  
    .footer-logo1 {
      @extend .px-3;
      @extend .pb-3;
      @extend .pt-3;
      grid-area: logo1;
      display: flex;
      justify-content: center;

      img{
        width: 293px;
        margin-bottom: 21px;
        @include media-breakpoint-up(lg) {
          width: 335px;
          margin-bottom: 0px;
        }

      }
  
      span{
        width: 6px;
            /* height: 100%; */
        background-color:  $color-primary-dark;
        display: block;
        margin: 0px 22px;
  
        @include media-breakpoint-up(lg) {
          height: 100px
        }
      }
  
      @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: flex-start
      }
    }
  
    
    .footer-msg-1 {
      @extend .px-3;
      @extend .pb-3;
      @extend .pt-3;
      grid-area: msg-1;
      
      display: flex;
      justify-content: center;
  
      @include media-breakpoint-up(lg) {
        padding-bottom: 0px !important;
      display: flex;
      align-items: end;
      justify-content: flex-start
        
      }
    }
    .footer-msg-2 {
      @extend .px-3;
      @extend .pb-3;
      @extend .pt-3;
      grid-area: msg-2;
      display: flex;
      justify-content: center;
  
      @include media-breakpoint-up(lg) {
        padding-top: 0px !important;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start
      }
    }
    .footer-nav {
      @extend .px-3;
      @extend .pb-3;
      @extend .pt-3;
      grid-area: nav;
  
  
      @include media-breakpoint-up(lg) {
        
        display: flex;
        align-items: center;
      }
      
      ul{
        list-style: none;
        display: flex;
        padding: 0px;
        flex-wrap: wrap;
        justify-content: center;
        @include media-breakpoint-up(lg) {
          margin-bottom: 0px ;
          
        }
  
        li{

  
          &:first-child{
            a{
              margin-left: 0px;
            }
          }
          a{
            font-size: 16px;
            color: #000;
            margin:10px
          }
        }
        
      }
  
      @include media-breakpoint-up(md) {
        li{
          
          
        }
      }
    }
    
    .footer-social {
      @extend .px-3;
      
      grid-area: social;
      padding-top: 0px;
      padding-bottom: 0px;
      div{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 75%;
        a{
          color: black;
          font-size: 3.5rem;

        }
      }
  
      @include media-breakpoint-up(lg) {
        padding-top: 1rem;
        padding-bottom: 1rem;
        div{
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          width: 100%;
          a{
            color: black;
            font-size: 2.5rem;
          }
        }
      }
    }
  
    
  }
  
  

  .component-footer-logo {
    margin: 0 auto;
    max-width: 10rem;
  }

 
  .component-footer-social {
    a {
      color: $font-color-white;
      display: inline-block;
      margin: 0 2rem;
      transition: color 0.5s ease;

      &:hover {
        color: $color-accent;
      }
    }

  }

  .link-footer {
    color: $color-primary-light;
    font-family: $sansserif-font;
    text-decoration: none;
    display: block;
    padding: 1rem;

    @include media-breakpoint-up(md) {
      display: inline-block;
      padding: 1rem 2rem;
    }

    &:hover {
      color: $color-accent;
      text-decoration: underline;
    }
  }

  .component-footer-copyright {
    text-align: center;
    color: #A5A5A5;
    padding: 1rem;
    font-family: $sansserif-font;
  }
}





.social-image-link {
  font-size: 2.4rem;
  @include media-breakpoint-up(lg) {
    font-size: 3.2rem;
  }
}

.insta-link{
  margin-left: 5px;
  color: inherit
}

.insta-link :hover {
  
  color: inherit
}

a.social-image-link:hover{
  color: #f96815 !important
}

.footer-grid .footer-nav ul li{
  padding: 0px 5px !important;
  margin: 2px 0px;

  a{
    font-size: 14px;
  }

  @include media-breakpoint-up(lg) {
   
  margin: 2px 0px;

    a{
      font-size: 16px;
    }
  }
}