@import "../colors";

.donate-thank-you-hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../static/hero-thank-you.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 95vh;

  &__hearts-1 {
    position:absolute;
    width:25%;
    top:10rem;
    left:0;
  }
  &__hearts-2 {
    position:absolute;
    width:25%;
    right:0;
    bottom:0;
  }
  .container {
    text-align: center;
    color: $color-white;
    max-width: 42rem;
  }

  .next-steps {
    display: block;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    text-align: center;
    color: $color-white;
    padding-bottom: 2rem;

    &:hover {
      text-decoration: none;
    }

    span {
      display: block;

      &.fal {
        padding-top:1rem;
        font-size: 2.4rem;
      }
    }
  }
}

.spread-the-word {
  background-color:$color-white;

  .container {
    max-width:51rem;
    margin:0 auto;
    padding:5rem 0;
    text-align:center;
  }
}

.thank-you-next-steps {
 background-color:$color-white;

  .container {
    max-width:114rem;
    margin:0 auto;
  }
  .btn-dl-instagram {
    display:inline-block;
    border:2px solid $color-primary-dark;
    color:$color-primary-dark;
    text-transform:uppercase;
    padding:1rem;
    margin-right:2rem;
    margin-bottom:2rem;
    font-weight:700;

    &:hover {
      text-decoration: none;
      color:$color-white;
    }
    &--blue {
     background-color:#98BDFA;
    }
    &--green {
     background-color:#57BF97;
    }
  }
}

.plus-minus-accordion {
  border-bottom: 1px solid grey;
  padding: 15px 0;

  &.active-true {
    .accordion-header {
      &:after {
        content: "-";
      }
    }
  }

  .accordion-header {
    position: relative;

    &:after {
      content: "+";
      display: block;
      position: absolute;
      right: 10px;
      top: 50%;
      line-height: 20px;
      margin-top: -10px;
    }
  }
}

.donate-thank-you-bg {
  background-color: $color-primary-light;

  @include media-breakpoint-up(md) {
    background-image: linear-gradient(90deg, $color-primary-light 50%, $color-primary-dark 50%);
  }
}

.donate-thank-you-tabs {

  &__content {
    position: relative;
    @include media-breakpoint-up(md) {
      display: flex;
    }

    .absolute-pixel--1 {
      display: none;
      top: 0;
      right: 0;

      @include media-breakpoint-up(md) {
        display: block;
        left: auto;
        right: calc(33.33% + 2rem);
      }

      .pixel--1 {
        @include media-breakpoint-up(md) {
          background-color: $color-accent;
          top: 0;
          right: 4rem;
        }
      }

      .pixel--2 {
        @include media-breakpoint-up(md) {
          top: 2rem;
          right: 2rem;
          background-color: $color-primary-dark;
        }
      }

      .pixel--3 {
        top: 0;
        right: 0;
        @include media-breakpoint-up(md) {
          background-color: $color-primary-offwhite;
        }
      }
    }

    &__item {

      &--thank-you-content {
        padding: 2rem 0;

        @include media-breakpoint-up(md) {
          width: 66.66%;
          background-color: $color-primary-light;
          padding: 2rem;
        }
        @include media-breakpoint-up(lg) {
          padding: 8rem 2rem 4rem 6rem;
        }
        //@include media-breakpoint-up(xl) {
        //  width: calc(50% + 114px);
        //}
      }

      &--faq {
        display: none;

        @include media-breakpoint-up(md) {
          min-height: calc(100vh - 30rem);
          display: block;
          width: 33.33%;
          background-color: $color-primary-dark;
          color: $color-primary-light;
          padding: 2rem;
        }
        @include media-breakpoint-up(lg) {
          padding-top: 6rem;
        }
        //@include media-breakpoint-up(xl) {
        //  width: calc(50% - 114px);
        //}

        //.container {
        //  @include media-breakpoint-up(xl) {
        //    max-width: calc(1140px * 0.4);
        //    margin: 0;
        //  }
        //}

        a,
        .span-link {
          color: $color-blue;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}

.component-thank-you {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
            "component-thank-you--header"
            "component-thank-you--description"
            "component-thank-you--buttons"
            "component-thank-you--image";

  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-template-areas:
            "component-thank-you--header        component-thank-you--header       "
            "component-thank-you--description   component-thank-you--description  "
            "component-thank-you--buttons       component-thank-you--buttons      "
            "component-thank-you--image         component-thank-you--image        ";
  }

  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: auto 48%;
    grid-template-rows: auto;
    grid-template-areas:
            "component-thank-you--header        component-thank-you--header       "
            "component-thank-you--description   component-thank-you--description  "
            "component-thank-you--buttons       component-thank-you--image        ";
    column-gap: 3rem;
    row-gap: 3rem;
  }

  &--header {
    grid-area: component-thank-you--header;

    display: flex !important;
    justify-content: center;
    flex-direction: column-reverse;

    @include media-breakpoint-up(md) {
      justify-content: space-between;
      align-items: center;
      flex-direction: row;

    }

    h2 {
      display: block;
      padding: 0px !important;
      font-weight: 600 !important;
      font-size: 20px !important;
      line-height: 23px !important;
      margin-top: 30px;

      @include media-breakpoint-up(md) {
        display: block;
        font-weight: 700 !important;
        font-size: 32px !important;
        line-height: 42px !important;

        margin-top: 0px
      }
    }

    img {
      width: 70%;
      margin: auto;

      text-align: center;
      @include media-breakpoint-up(md) {
        width: 26%;
        margin: 0px;
      }
    }
  }

  &--image {
    grid-area: component-thank-you--image;
    justify-self: center;
    align-self: center;
  }

  &--buttons {
    grid-area: component-thank-you--buttons;

    a,
    button {
      min-width: 2rem;
      border-radius: none;

      &:hover {
        background-color: $color-accent;
      }

      @include media-breakpoint-up(md) {
        border-radius: inherit;
      }
    }

    &__social {
      padding: 0rem;
      display: flex;
      justify-content: space-between;
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 100%;
      }

      &__item {
        padding: 1rem 3rem !important;
        @include media-breakpoint-up(md) {
          width: 24%;
        }
      }
    }
  }

  &--description {
    @extend .py-3;
    grid-area: component-thank-you--description;

    p {
      display: block;
      padding: 0px !important;

      @include media-breakpoint-up(md) {
        display: block;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 20px !important;


      }
    }
  }

}

.component--copy {
  .form-control {
    font-size: 1.8rem;
    height: 4rem;
    padding: 0 1rem;
  }

  .btn {
    min-width: 0;
    width: 100%;
    font-size: 1.6rem;
    padding: 0 1rem;
    line-height: 4rem;
  }
}

//.btn--download-supporter-graphic {
//  font-size:1.8rem;
//  width:100%;
//  @include media-breakpoint-up(md) {
//    width:auto;
//  }
//}

.tq-steps {
  h2 {
    font-family: $worksans-font;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 23px !important;
  }

  p {
    margin-top: 15px;
    font-family: $worksans-font;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 19px !important;
  }
}


.thanku--content {
  position: relative;
  padding-top: 15rem;
  padding-bottom: 5rem;

  @include media-breakpoint-up(md) {
    padding-top: 20rem;
    padding-bottom: 5rem;


  }

  .container {


    @include media-breakpoint-up(md) {
      width: 85rem;


    }
  }
}


.steps-count {
  margin-top: 39px
}

.upload-des {
  padding-top: 33px !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
}

.btn-stack-cust {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 15px;

  @include media-breakpoint-up(md) {
    width: 85rem;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
}

.map-thanku {
  margin-top: 50px;
  @include media-breakpoint-up(md) {
    margin-top: 50px;
  }
}