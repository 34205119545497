.sponsors-hero {
  background-image: url("../../static/donation-page-banner_without-in-support-of-st-pauls-foundation_logo.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 25rem;
  position: relative;

  &:before {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    background-color: rgba($color-primary-dark, 0.6);
  }
}

.sponsors-content {
  position: relative;

  .absolute-pixel--1 {
    top: 0;
    left: 0;

    @include media-breakpoint-up(md) {
      left: 4rem;
    }

    .pixel--1 {
      top: 0;
      left: 0;
      @include media-breakpoint-up(md) {
        background-color: $color-accent;
      }
    }

    .pixel--2 {
      top: 2rem;
      left: 2rem;
      @include media-breakpoint-up(md) {
        background-color: $color-primary-dark;
      }
    }

    .pixel--3 {
      top: 0;
      left: 4rem;
      @include media-breakpoint-up(md) {
        background-color: $color-primary-dark;
      }
    }
  }

  .absolute-pixel--2 {
    bottom: 0;
    right: 0;

    @include media-breakpoint-up(md) {
      right: 4rem;
    }

    .pixel--1 {
      bottom: 2rem;
      right: 0;
      @include media-breakpoint-up(md) {
        bottom: 0;
        right: 4rem;
        background-color: $color-primary-dark;
      }
    }

    .pixel--2 {
      bottom: 0;
      right: 2rem;
      @include media-breakpoint-up(md) {
        background-color: $color-accent;
        bottom: 2rem;
      }
    }

    .pixel--3 {
      bottom: 0;
      right: 0;
    }
  }

  &--container {
    @extend .py-5;
  }

  .sponsors-title {
    padding: 4rem 0;
    
    text-align: center;
    font-family: $sansserif-font;

    @include media-breakpoint-up(md) {
      padding-top: 6rem;
    }
  }
}

.sponsors-block-parent{
  display: flex;
  justify-content: center;
  margin: 10px 6px;
}

.sponsors-wrapper {

  padding: 3rem 0;
  padding-top:0px !important;

  border-bottom: 2px solid gray;

  h6{
    font-size: 20px;

    @include media-breakpoint-up(md) {
      font-size: 20px;
    }
  }

  .sponsors-block {
    
    height: 153px;
    width: 151px;
    border: 2px solid black;
    border-radius: 5px;
    position: relative;

    @include media-breakpoint-up(md) {
      height: 193px;
    width: 191px;
    }

    .company-image{
      height: 108px;
      width: 100%;
      position: absolute;
      top: 0px;
      left: 0px;

      @include media-breakpoint-up(md) {
        height: 135px;
      
      }
      @include media-breakpoint-up(lg) {
        height: 137px;
      
      }

      background-color: #fff;
      
      
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;


      a{
        height: 108px;
        width: 100%;
        max-height: 141px;

        display: flex;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-up(md) {
          height: 193px;
        
        }
        
      }
      
    }

    .company-name{
      height: 41px;
      width: 100%;
      position: absolute;
      bottom: -1px;
      left: 0px;
      display: flex;
      align-items: center;
      background-color: black;
      color: #fff;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;

      font-family: $worksans-font;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: center;
      justify-content: center;

      @include media-breakpoint-up(md) {
        height: 52px;
      
      }

    }

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 115px;
      padding: 8px;

      @include media-breakpoint-up(md) {
        max-height: 139px;
      
      }
    }

    .logo-text {
      font-family: $serif-font;
      font-size: 1.4rem;
      line-height: 2.6rem;
      font-weight: 700;
      text-decoration: none;
      color: $color-primary-dark;
    }
  }
}

.sponsor-list{
  background-color: #F6EFE5;
    padding-top: 4rem  ;

    @include media-breakpoint-up(lg) {
      padding-top: 0px !important;
    
    }
}



.container-list{
  
  :last-child{
    div{
      border:none
    }
  }
}



.color-rand-0{

  border: 2px solid #F96815 !important;

  .company-name{
    background-color: #F96815 !important;
  }

  
}

.color-rand-1{

  border: 2px solid #98BDFA !important;

  .company-name{
    background-color: #98BDFA !important;
  }

  
}

.color-rand-2{

  border: 2px solid #41265A !important;

  .company-name{
    background-color: #41265A !important;
    
  }

  
}

.color-rand-3{

  border: 2px solid #02A574 !important;

  .company-name{
    background-color: #02A574 !important;
  }

  
}

.color-rand-4{

  border: 2px solid #66DEF4 !important;

  .company-name{
    background-color: #66DEF4 !important;
  }

  
}

.color-rand-5{

  border: 2px solid #507CF3 !important;

  .company-name{
    background-color: #507CF3 !important;
  }

  
}

.color-rand-6{

  border: 2px solid #E94568 !important;

  .company-name{
    background-color: #E94568 !important;
  }

  
}

.color-rand-7{

  border: 2px solid #F9F15A !important;

  .company-name{
    background-color: #F9F15A !important;
    color: black !important;
    font-weight: 400;
  }

  
}

.color-rand-8{

  border: 2px solid #E7A04E !important;

  .company-name{
    background-color: #E7A04E !important;
  }

  
}

.color-rand-9{

  border: 2px solid #43DBA7 !important;

  .company-name{
    background-color: #43DBA7 !important;
  }

  
}




.venue-sponsor{
  padding-top: 0px !important;
}

.hero-sponsorPage{
  
  flex-direction: row !important;
  align-items: center;
  padding: 7rem 2rem !important;
  background-color: #151515 !important;
  min-height: 72vh;
  position: relative;
}

.component-sponsor-hero {

  position: relative;
  display: grid;
  grid-template-columns: 100% !important;
  grid-template-rows: auto;
  padding: 0px !important;
  grid-template-areas: 
                       "head "
                       "text "
                       "btn ";

  @include media-breakpoint-up(md) {
    display: grid;
  grid-template-columns: 70% 30% !important;
  grid-template-rows: auto;
  padding: 0px !important;
  grid-template-areas: 
                       "head image"
                       "text image"
                       "btn image";
  }

  .component-sponsor-hero-heading {
    @extend .px-3;
    @extend .pb-3;
    font-weight: 700;
    font-size: 26px;
    grid-area: head;
    color: #fff;
    text-align: left;
    width: 100%;
    font-family: $degular-font;

    padding: 70px 7px 10px 7px    !important;
    

    @include media-breakpoint-up(md) {
      justify-self: start;
      font-weight: 700;
    font-size: 40px;
    grid-area: head;
    color: #fff;
    text-align: left;
    width: 100%;

    padding: 70px 38px 10px 58px    !important;
    }

    span{
      color: #F96815
    }
  }

  

  .component-sponsor-hero-text {
    @extend .px-3;

    grid-area: text;

    text-align: left;
    justify-self: start;
    align-self: start;
    line-height: 1.25;
    padding: 10px 7px 10px 7px    !important;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    
    @include media-breakpoint-up(md) {
      grid-area: text;

    text-align: left;
    justify-self: start;
    align-self: start;
    line-height: 1.25;
    padding: 10px 38px 10px 58px    !important;
    font-size: 24px;
    font-weight: 400;
    color: #fff;
      
    }

    p{
      font-size: 2.0rem;
    padding: 50px 40px;
    color: white
    }

  }

  .component-sponsor-hero-btn {
    @extend .px-3;

    grid-area: btn;

    text-align: left;
    justify-self: start;
    align-self: start;
    line-height: 1.25;
    width: 100%;
    padding: 10px 7px 10px 7px    !important;
    @include media-breakpoint-up(md) {
      grid-area: btn;

    text-align: left;
    justify-self: start;
    align-self: start;
    line-height: 1.25;
    padding: 10px 38px 10px 58px    !important;
      
    }

    p{
      font-size: 2.0rem;
    padding: 50px 40px;
    color: white
    }

  }

  
}

#sponsor-pixel-moments{
  
  padding-bottom: 0px;
  background-color: #F6EFE5 !important;

  @include media-breakpoint-up(md) {
    background-color: #F6EFE5 !important;
  padding-bottom: 60px;
    
  }
}

.sponsor-back-1{
  position: absolute;
  right: 83px;
  bottom: -60px;
}


.sponsor-strip{
  display: flex;
 flex-wrap: wrap; 
}

.pb-0{
  padding-bottom: 0px !important;
  padding-top: 25px !important;
}

