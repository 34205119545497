@import 'vendor';
@import 'colors';
@import 'fonts';
@import 'misc';
@import 'components/buttons';
@import 'components/header';
@import 'components/footer';
@import 'components/hero';
@import 'components/stories-carousel';
@import 'components/location-carousel';
@import 'components/about-card-image';
@import 'components/stories-form';
@import 'components/donate-steps';
@import 'components/what-is';
@import 'components/modal-vmf';
@import 'pages/homepage';
@import 'pages/about';
@import 'pages/artwork';
@import 'pages/sponsors';
@import 'pages/donate';
@import 'pages/faq';
@import 'pages/donate-thank-you';
@import 'pages/basic-page';

body {
  color: $color-primary-dark;
  line-height: 1.6;

  background-color: #151515;
}


.bg-white{
  background-color: #fff;
}

.scroll-none {

  overflow: hidden;
}

.test-border-red {
  border: 1px solid red;
}

.test-border-blue {
  border: 1px solid blue;
}

input:focus,
textarea:focus {
  outline-color: $color-accent;
}

.bg-light-grey {
  background-color: $color-primary-light;
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
}

.modal-content {
  h1 {
    font-size: 3rem;
    margin-bottom: 2rem;
  }

  h2 {
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }

  p {
    margin-bottom: 2rem;
    font-size: 1.6rem;
  }
}

.container{
  @include media-breakpoint-up(lg) {
    
    max-width: 1324px;
  }
}

.pm-header{

}

