.btn {
  font-family: $sansserif-font;
  font-size: 2rem;
  font-weight: 700;
  padding: 0.5rem 2rem;
  display: inline-block;
  border: 0;
  line-height: 4rem;
  min-width: 18rem;

  &:hover {
    color: $color-primary-light;
  }

 

  &--bg-dark {
    background-color: $color-primary-dark;
    color: $color-primary-light;
  }

  &--bg-accent {
    background-color: $color-accent;
    color: $color-primary-light;

    &:hover {
      background-color: orangered;
    }

    &--twitter {
      background-color: $color-twitter;
      color: $color-primary-light;
    }

    &--facebook {
      background-color: $color-facebook;
      color: $color-primary-light;
    }

    &--instagram {
      background-color: $color-instagram;
      color: $color-primary-light;
    }

    &--mail {
      background-color: $color-mail;
      color: $color-primary-light;
    }
  }

  &--rounded {
    border-radius: 2.5rem;
  }

  &--outline {
    box-sizing: border-box;
    border: 2px solid $font-color-secondary;
    background-color: $color-primary-light;

    &:hover {
      background-color: $color-accent;
      border: 2px solid $color-accent;
      color: $font-color-white;
    }
  }

  &--gradient {
    background-image: linear-gradient(90deg, $color-accent, orangered);
    color: $color-primary-light;
  }

  &--stack {
    color: $color-primary-light;
    position: relative;
    border-radius: 0px;
    padding: 0px !important;
    font-family: $degular-font;
    font-size: 24px;
    font-weight: 700;

    &:before{
      transform: translate(8px, 8px);
      content: "";
      position: absolute;
      top: -3px;
      left: -2px;
      width: 100%;
      height: 100%;
      background-color: #FEC045;
      border: 2px solid black;
      border-radius: 0px;
      transition: transform 0.3s;
      z-index: 1
    }
    span{
      z-index: 1;
      z-index: 1;
      display: block;
      background-color: #FEC045;
      position: relative;
      color: black;
      padding: 1rem 2rem !important;
      width: 100%;
      border: 2px solid black;
    }
    &:after{
      transform: translate(16px, 16px);
      content: "";
      position: absolute;
      top: -6px;
      left: -5px;
      width: 100%;
      height: 100%;
      background-color: #FEC045;
      border: 2px solid black;
      border-radius: 0px;
      transition: transform 0.3s;
    }

    &:hover{
      &:before{
        transform: translate(8px, 8px);
        content: "";
        position: absolute;
        top: -3px;
        left: -2px;
        width: 100%;
        height: 100%;
        background-color: #F96815;
        border: 2px solid white;
        border-radius: 0px;
        transition: transform 0.3s;
        z-index: 1
      }
      span{
        z-index: 1;
        z-index: 1;
        display: block;
        background-color: #F96815;
        position: relative;
        color: #fff;
        padding: 1rem 2rem !important;
        width: 100%;
        border: 2px solid #fff;
      }
      &:after{
        transform: translate(16px, 16px);
        content: "";
        position: absolute;
        top: -6px;
        left: -5px;
        width: 100%;
        height: 100%;
        background-color: #F96815;
        border: 2px solid #fff;
        border-radius: 0px;
        transition: transform 0.3s;
      }
    }

    &:focus{
      box-shadow: none !important;
    }
    
    &:active{
      &:before{
        transform: translate(2px, 3px);
        content: "";
        position: absolute;
        top: -3px;
        left: -2px;
        width: 100%;
        height: 100%;
        background-color: #F96815;
        border: 2px solid white;
        border-radius: 0px;
        transition: transform 0.3s;
        z-index: 1
      }
      span{
        z-index: 1;
        z-index: 1;
        display: block;
        background-color: #F96815;
        position: relative;
        color: #fff;
        padding: 1rem 2rem !important;
        width: 100%;
        border: 2px solid #fff;
      }
      &:after{
        transform: translate(5px, 6px);
        content: "";
        position: absolute;
        top: -6px;
        left: -5px;
        width: 100%;
        height: 100%;
        background-color: #F96815;
        border: 2px solid #fff;
        border-radius: 0px;
        transition: transform 0.3s;
      }
    }

  }
  
  &--blue-outline{
    background-color: #98BDFA !important;
    color: #000 !important;
    font-weight: 700 !important;
    border:2px solid black !important;
    border-radius: 0px !important;
    padding: 1rem 3rem !important;

    &:hover{
      background-color: #507CF3 !important;
      color: #fff !important;
      font-weight: 700 !important;
      border:2px solid #507CF3 !important;
      border-radius: 0px !important;
      padding: 1rem 3rem !important;
    }

    &:focus{
      background-color: #98BDFA !important;
      color: #000 !important;
      font-weight: 700 !important;
      border:3px solid #507CF3 !important;
      border-radius: 0px !important;
      padding: 1rem 3rem !important;
      box-shadow: none;
    }

  }
}

.faq--buttons--social {
  width: 4rem;
  line-height: 3rem;
  margin-right: 1rem;
  min-width: 0;
  padding: 0 0 0.5rem;
  text-align: center;


  @include media-breakpoint-up(md) {
    width: 6rem;
    line-height: 5rem;
    margin-right: 2rem;
  }

  &:hover {
    background-color: $color-accent;
  }

  img {
    max-width: 3rem;
    max-height: 2rem;

    @include media-breakpoint-up(md) {
      max-width: 4rem;
      max-height: 3rem;
    }
  }
}

.btn--floatleft{
  float: right;
}