.stories-carousel-mobile {
  @mixin details-active { // "Read Stories" button is clicked
    .stories-carousel-mobile.is-details-active & {
      @content;
    }
  }

  @mixin slide-active { // Current slide
    .BrainhubCarouselItem--active & {
      @content;
    }
  }

  @mixin details-slide-active { // Current slide after "Read Stories" button is clicked
    .stories-carousel-mobile.is-details-active .BrainhubCarouselItem--active & {
      @content;
    }
  }

  $root: &;
  $slider-height: 70vw;

  overflow: hidden;
  position: relative;

  &__inner {
    width: 180vw;
    @include details-active {
      width: 200%;
    }
  }

  &__slide {
    position: relative;
    padding: 1.5rem;
    width: 100%;

    &__title {
      position: absolute;
      z-index: 5;
      top: 1.5rem;
      left: 1.5rem;
      right: 1.5rem;
      text-align: center;
      height: $slider-height;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(#C4C4C4, 0.8);
      color: $color-primary-dark;

      @include details-active {
        display: none;
      }
    }

    &__image {
      width: 100%;
      height: $slider-height;
      object-fit: cover;
      object-position: 50% 50%;

      @include details-active {
        display: block;
        position: absolute;
        height: calc(100% - 3rem);
        width: calc(100% - 3rem);
      }
    }

    &__button {
      background-color: $color-primary-dark;
      color: $color-primary-light;
      border: 0;
      padding: 0.5rem 1rem;
      display: block;
      text-align: center;
      width: 100%;
      font-family: $sansserif-font;

      @include details-active {
        display: none;
      }
    }

    &__details {
      display: none;
      background-color: rgba(#5F5F5F, 0.8);
      color: $color-primary-light;
      padding: 7rem 1.5rem;
      position: relative;
      opacity: 0;
      transition: 0.3s opacity;
      min-height: 55rem;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-family: $sansserif-font;
      text-align: center;

      @include details-active {
        display: flex;
        opacity: 1;
      }

      &__title {
        margin-bottom: 4rem;
      }

      &__close {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        z-index: 5;

        &__button {
          border: 0;
          padding: 1rem 2rem;
          background: none;
          font-size: 4rem;
          line-height: 1;
          color: $color-primary-light;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  &__dots {
    text-align: center;

    @include details-active {
      display: none;
    }

    &__item {
      display: inline-block;
      margin: 1rem;
      padding: 0;
      border: 0;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      background-color: $color-primary-light;

      &.is-active {
        background-color: $color-accent;
      }
    }
  }

  &__arrows {
    position: absolute;
    bottom: 1rem;
    left: 0;
    right: 0;
    text-align: center;
    display: none;

    @include details-active {
      display: block;
    }

    &__prev,
    &__next {
      border: 0;
      padding: 1rem 2rem;
      background: none;
      font-size: 4rem;
      line-height: 1;
      color: $color-primary-light;

      &:disabled {
        color: darken($color-primary-light, 70%);
      }

      &:focus {
        outline: none;
      }
    }
  }
}

// .stories-carousel-desktop {
//   @mixin details-active { // "Read Stories" button is clicked
//     .stories-carousel-desktop.is-details-active & {
//       @content;
//     }
//   }

//   @mixin slide-active { // Current slide
//     .BrainhubCarouselItem--active & {
//       @content;
//     }
//   }

//   @mixin details-slide-active { // Current slide after "Read Stories" button is clicked
//     .stories-carousel-desktop.is-details-active .BrainhubCarouselItem--active & {
//       @content;
//     }
//   }

//   $root: &;
//   $slider-width: 160rem;

//   overflow: hidden;
//   position: relative;
//   margin: 0 auto;
//   max-width: $slider-width;

//   &__inner {
//     width: $slider-width;
//     margin-left: 50%;
//     transform: translateX(-50%);
//   }

//   &__slide {
//     position: relative;
//     width: 100%;

//     @include slide-active {
//       padding: 5rem;
//     }
//     @include details-slide-active {
//       padding: 0;
//     }

//     &__overlay {
//       position: absolute;
//       top: 0;
//       left: 0;
//       right: 0;
//       bottom: 0;
//       //background-color: rgba($color-white, 0.7);
//       z-index: 5;
//       justify-content: center;
//       align-items: center;
//       display: flex;
//       flex-direction: column;

//       @include slide-active {
//         top: 5rem;
//         left: 5rem;
//         right: 5rem;
//         bottom: 5rem;
//         //background-color: rgba($color-bg-dark-gray, 0.5);
//       }
//       @include details-slide-active {
//         display: none;
//       }

//       &__title {
//         display: none;
//         color: $color-primary-light;

//         @include slide-active {
//           display: block;
//         }
//       }

//       &__button {
//         display: none;
//         color: $color-accent;
//         border: 0;
//         background: none;
//         text-decoration: underline;

//         @include slide-active {
//           display: block;
//         }
//       }
//     }

//     &__image {
//       background-color: $color-white;
//       @include slide-active {
//         background-color: #272727;
//       }

//       img {
//         width: 100%;
//         height: 30rem;
//         object-fit: cover;
//         object-position: 50% 50%;
//         //transition:0.3s height;
//         transition: 0.3s opacity;
//         opacity: 0.5;

//         @include slide-active {
//           height: 40rem;
//           //opacity: 0.5;
//         }
//         @include details-slide-active {
//           height: 50rem;
//           opacity: 0.25;
//         }
//       }
//     }

//     &__details {
//       display: none;
//       position: absolute;
//       top: 0;
//       left: 0;
//       right: 0;
//       bottom: 0;
//       //background-color: rgba($color-primary-dark, 0.8);
//       color: $color-primary-light;
//       padding: 5rem;

//       @include details-slide-active {
//         display: block;
//       }

//       &__title {
//         text-transform: uppercase;
//         text-align: center;
//         padding: 3rem 10rem 8rem;
//       }

//       &__description {
//         text-align: left;
//       }

//       &__close {
//         display: none;
//         position: absolute;
//         top: 8rem;
//         right: 5rem;
//         z-index: 5;

//         @include details-slide-active {
//           display: block;
//         }

//         &__button {
//           border: 0;
//           padding: 0;
//           background: none;
//           font-size: 3rem;
//           line-height: 1;
//           color: $color-primary-light;
//         }
//       }
//     }
//   }

//   &__arrows {
//     border: 0;
//     padding: 0;
//     background: none;
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     z-index: 5;
//     color: $color-primary-light;
//     transition: padding-left 0.3s, padding-right 0.3s;

//     &:focus {
//       outline: none;
//     }

//     @include details-active {
//       top: auto;
//       bottom: 2rem;
//       transform: none;
//     }

//     i {
//       display: none;
//       font-size: 5rem;

//       @include details-active {
//         display: block;
//       }
//     }

//     img {
//       @include details-active {
//         display: none;
//       }
//     }

//     &--prev {
//       left: 50%;
//       margin-left: ($slider-width / 3)  * -1;
//       padding-left: 2rem;
//       padding-right: 0;

//       &:hover {
//         padding-right: 2rem;
//         padding-left: 0;
//       }

//       @include details-active {
//         left: 40%;
//         margin-left: 0;
//       }
//     }

//     &--next {
//       right: 50%;
//       margin-right: ($slider-width / 3)  * -1;
//       padding-right: 2rem;
//       padding-left: 0;

//       &:hover {
//         padding-left: 2rem;
//         padding-right: 0;
//       }

//       @include details-active {
//         right: 40%;
//         margin-right: 0;
//       }
//     }
//   }
// }



.component-stories-desktop {

  background-color: #00000099;
  
  display: grid;
  grid-template-columns: 100% !important;
  grid-template-rows: auto;
  padding: 0px !important;
  grid-template-areas: "head"
                       " main "
                       "nav"
                       "foot";

  @include media-breakpoint-up(md) {
    background-color: #00000099;
  border: 3px solid white ;
  display: grid;
  grid-template-columns: 50% 50% !important;
  grid-template-rows: auto;
  padding: 0px !important;
  grid-template-areas: "head head"
                       "cont cont"
                       "testi testi";
  }

  .compenent-stories-desktop-heading {
    @extend .px-3;
    @extend .pb-3;
    font-weight: 600;
    grid-area: head;
    color: black;
    justify-self: start;
    align-self: end;
    width: 100%;
    color: white;
    text-align: center;
    
    padding: 50px 38px 30px 38px     !important;

    @include media-breakpoint-up(md) {
      justify-self: start;
      text-align: left;
      padding: 30px 38px     !important;
    }
  }

  .component-stories-desktop-container  {
    @extend .flex-center;

    
    grid-area: cont;
    

    @include media-breakpoint-up(md) {
      border-top:3px solid white;
    }
  }

  .component-stories-desktop-testi {
    @extend .flex-center;

    position: relative;
    grid-area: testi;
    

    @include media-breakpoint-up(md) {
      border-top:3px solid white;
    }
  }

  

  
}


.component-stories-desktop-inner-container {

  background-color: #000000db;
  
  display: grid;
  

  @include media-breakpoint-up(md) {
    background-color: #00000099;
  height: 100%;
  display: grid;
  grid-template-columns: 40% 60% !important;
  grid-template-rows: auto;
  padding: 0px !important;
  grid-template-areas: "img text";
  }

  
  .component-stories-desktop-inner-container-text  {
  padding: 50px 60px;
    
    h3{
      font-size: 36px;
      color: #fff;
      font-weight: 700;
      margin-bottom: 35px;
      font-family: $degular-font;
    }

    p{
      font-size: 16px;
      color: #fff;
      font-weight: 400;
      line-height: 24px;
    }
    
    grid-area: text;
    

    
  }

  .component-stories-desktop-inner-container-img  {
    @extend .flex-center;

    border-right: 3px solid white;
    grid-area: img;

    iframe{
      border: none;
    }
    

    
  }

  

  

  
}

.hideen{
  display: none;
}

.stories-desktop-title{
  h1{
    margin-bottom: 25px;
    font-size: 56px;
  }

  p{
    font-size: 20px;
  }
}


.stories-parent-mobile-title{
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin-top: 15px;
}

.stories-parent-mobile-text{
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  padding: 0px 10px;
  margin-top: 20px;
}

.stories-parent-mobile-image-parent{
  display: flex;
  justify-content: center;
  align-items: center;
}

.stories-parent-mobile-image-container{
  height: 156px;
  width: 294px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-up(md) {
    height: 329px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  }

  iframe{
    width: 100%;
    height: 100%;
    @include media-breakpoint-up(md) {
      width: 100%;
    height: 100%;
    }
  }

  img{
    max-height: 156px;
  }
}

.BrainhubCarouselItem {
  
  align-items: flex-start  !important;
 
}

.mha-cont{
  padding: 0px;
  position: relative;

  

  h2{
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 16px;

    text-align: center;

    font-family: $degular-font;

    @include media-breakpoint-up(md) {
      font-size: 56px;
    font-weight: 700;
    }
  }

  p{
    font-size: 16px;
    font-weight: 400;
    color: #fff;
  }
}


.stories-carousel-mobile  {
  padding-top: 10px;
  padding-bottom: 10px;

  @include media-breakpoint-up(md) {
    padding: 0px;
  
  }
}


.container-inner-testi{
  padding: 56px 268px;

  @include media-breakpoint-up(md) {
    padding: 56px 268px;
  
  }
  

p{
  font-family: Work Sans;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
}

  color: #fff;
}

.author-testi{

  margin-top:29px;

  P{
    font-family: Work Sans;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
margin:0px
  }
}


.under-story{
  position: relative;
  background-color: #00000066;
  padding-top: 64px;
  
  @include media-breakpoint-up(lg) {
    padding-top: 121px;
  
  }
}

.container-inner-testi-m{
  position: relative;
  padding: 10px 10px!important;
  padding-top: 10px;


}
