@import '../colors';


.modal-vmf {
  .modal-content {
    background-color: $color-primary-dark;
    border: 1.5rem solid $color-secondary;
    color: $color-white;

    .close-bar {
      margin-left: 40%;
      text-align: right;
      background: rgba(233, 69, 104, 0.5);
      border-bottom-left-radius: 4rem;

      @include media-breakpoint-up(md) {
        border-bottom-left-radius: 8rem;
      }

      button {
        border: 0;
        margin: 0;
        padding: 0;
        text-align: center;
        background: none;
        color: $color-white;
        line-height: 4rem;
        width: 4rem;
        font-size: 2.4rem;

        @include media-breakpoint-up(md) {
          line-height: 8rem;
          width: 8rem;
          font-size: 4rem;
        }
      }
    }

    .modal-body {
      padding: 2rem;
      position: relative;

      @include media-breakpoint-up(lg) {
        padding: 5rem 10rem;
      }

      p {
        font-size: 1.6rem;

        @include media-breakpoint-up(md) {
          font-size: 2rem;
        }
      }

      .img-pixel-moments-vmf {
        width: 100%;
        display: block;
        margin-bottom: 5rem;
      }

      .vmf-location-time {
        padding-left: 40%;
      }

      .green-square {
        position: absolute;
        bottom: 0;
        left: 3rem;
        width: 10rem;
        height: 25rem;

        @include media-breakpoint-up(md) {
          left: 6rem;
          width: 20rem;
        }

        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 30%;
          bottom: 20%;
          left: 0;
          right: 60%;
          background: rgba(2, 165, 116, 0.5);
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 40%;
          right: 0;
          background: rgba(2, 165, 116, 0.5);
        }
      }
    }
  }
}