.component-image-card {
  @extend .text-center;

  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
            "component-image-card-header"
            "component-image-card-image"
            "component-image-card-copy"
            "component-image-card-button";

  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    grid-template-areas:
            "component-image-card-image  component-image-card-header"
            "component-image-card-image  component-image-card-copy"
            "component-image-card-image  component-image-card-button";
  }

  .component-image-card-header {
    grid-area: component-image-card-header;
    @include media-breakpoint-up(md) {
      justify-self: start;
      align-self: end;
    }
  }

  .component-image-card-copy {
    text-align: left;
    grid-area: component-image-card-copy;
  }

  .component-image-card-image {
    grid-area: component-image-card-image;
    text-align: center;

    img {
      max-width: 15rem;

      @include media-breakpoint-up(md) {
        max-width: 100%;
      }
    }
  }

  .component-image-card-button {
    grid-area: component-image-card-button;

    a {
      display: block;
      margin: 0 auto;
      @include media-breakpoint-up(lg) {
        width: 50%;
      }
    }
  }

}
