.location-carousel {
  position: relative;

  &__slide {
    width: 100%;

    h2 {
      text-align: center;
      margin-bottom: 4rem;

      @include media-breakpoint-up(md) {
        text-align: left;
        margin-bottom: 2rem;
      }
    }

    .bg-light {
      //background-color: $color-primary-light;
      height: 60rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include media-breakpoint-up(md) {
        padding-right: 8rem
      }
      @include media-breakpoint-up(lg) {
        padding-right: 0;
      }
    }

    .bg-dark {
      color: $color-primary-light;
      background-color: $color-primary-dark;
      height: 60rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include media-breakpoint-up(md) {
        padding-left: 8rem
      }
      @include media-breakpoint-up(lg) {
        padding-left: 0;
      }
    }

    .slide-container {
      padding: 0 2rem;
      margin: 0 auto;
      max-width: 100rem;
    }

    .map-slide {
      @include media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: 55% auto;
        grid-template-rows: auto auto;
        grid-template-areas:
            "title image"
            "body  image";
        column-gap: 2rem;
      }
      @include media-breakpoint-up(lg) {
        column-gap: 10rem;
      }

      &__title {
        grid-area: title;
      }

      &__image {
        grid-area: image;
        margin: 0 auto 2rem;
        width: 25rem;
        @include media-breakpoint-up(md) {
          align-self: center;
          width: auto;
          margin: 0;
        }

        img {
          width: 100%;
        }
      }

      &__body {
        grid-area: body;
      }
    }
  }

  &__dots {
    text-align: center;
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    right: 2rem;
    z-index: 5;

    &__item {
      background-color: #C4C4C4;
      border: 0;
      padding: 0;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      margin: 0 1rem;

      &:focus {
        outline: none;
      }

      &.is-active {
        background-color: $color-accent;
      }
    }
  }

  &__arrows {
    border: 0;
    padding: 0;
    background: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    cursor: pointer;
    color: $color-primary-light;
    transition: padding-left 0.3s, padding-right 0.3s;
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      display: none;
    }

    i {
      font-size: 5rem;
    }

    &--prev {
      left: 3rem;
      padding-left: 2rem;
      padding-right: 0;

      &:hover {
        padding-right: 2rem;
        padding-left: 0;
      }
    }

    &--next {
      right: 3rem;
      padding-right: 2rem;
      padding-left: 0;
      color: $color-primary-dark;

      &:hover {
        padding-left: 2rem;
        padding-right: 0;
      }
    }
  }
}
