.component-donate-steps {

  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  grid-template-areas:
  "component-donate-steps-image           component-donate-steps-image"
  "component-donate-steps-header          component-donate-steps-header"
  "component-donate-steps-description     component-donate-steps-description"
  "component-footer-terms-and-conditions  component-footer-terms-and-conditions";

  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
            
            "component-donate-steps-image           component-donate-steps-image"
            "component-donate-steps-header          component-donate-steps-header"
            "component-donate-steps-description     component-donate-steps-description"
            "component-footer-terms-and-conditions  component-footer-terms-and-conditions";
  }

  .component-donate-steps-header {
    @extend .px-3;
    @extend .pb-3;
    font-weight: 500;
    font-size: 20px;
    font-family: $worksans-font;
    grid-area: component-donate-steps-header;
    color: black;
    justify-self: start;
    align-self: end;
    justify-self: center;

    @include media-breakpoint-up(md) {
      justify-self: center;
    }
  }

  .component-donate-steps-image {
    @extend .flex-center;

    @extend .mb-md-5;
    @extend .pb-md-5;
    grid-area: component-donate-steps-image;



    @include media-breakpoint-up(md) {
    }
  }

  .component-donate-steps-description {
    @extend .px-3;

    grid-area: component-donate-steps-description;

    font-weight: 400;
    font-size: 16px;
    font-family: $worksans-font;
    color: #000;
    text-align: left;
    justify-self: start;
    align-self: start;
    line-height: 1.25;

    @include media-breakpoint-up(md) {
      justify-self: center;
      
    }
  }

  .component-footer-terms-and-conditions {
    @extend .flex-center;

    grid-area: component-footer-terms-and-conditions;

    @include media-breakpoint-up(md) {
    }
  }
}

.donate-step {
  &__1 {
    @extend .pb-4;
    img {
      height: 150px;
      width: auto;
      @include media-breakpoint-up(md) {
        height: 284px;
        
      }
    }
  }

  &__2 {
    @extend .pb-4;
    img {
      height: 150px;
      width: auto;
      @include media-breakpoint-up(md) {
        height: 284px;
        
      }
    }
    // @include media-breakpoint-up(md) {
    //   img {
    //     height: 10rem;
    //     width: auto;
    //   }
    // }
  }

  &__3 {
    @extend .pb-4;
    img {
      height: 150px;
      width: auto;
      @include media-breakpoint-up(md) {
        height: 284px;
        
      }
    }
  }
}

.btn--homepage-faq {
  width: 100%;
  max-width: 40rem;
}

.participate-text {
  margin: 0 auto;
  max-width: 88rem;
}

.steps-container{
  margin-top:61px
}

.steps-container-2{
  margin-top:61px;
  height: 400px;
}



.component-previous-mural {

  background-color: #000;
  
  display: grid;
  grid-template-columns: 100% !important;
  grid-template-rows: auto;
  padding: 0px !important;
  grid-template-areas: "head"
                       " main "
                       "nav"
                       "foot";

  @include media-breakpoint-up(lg) {
    background-color: #000000db;
  border: 3px solid white ;
  display: grid;
  grid-template-columns: 48% 52% !important;
  grid-template-rows: auto;
  padding: 0px !important;
  grid-template-areas: "head head"
                       "nav  main"
                       "nav  foot";
  }

  .compenent-previous-mural-heading {
    @extend .px-3;
    @extend .pb-3;
    font-weight: 600;
    grid-area: head;
    color: black;
    justify-self: start;
    align-self: end;
    width: 100%;
    color: white;
    text-align: center;
    
    padding: 50px 38px 30px 38px     !important;

    @include media-breakpoint-up(lg) {
      justify-self: start;
      text-align: left;
      padding: 30px 38px     !important;
      
      div{
          h2{
            font-size: 64px
          }
      }


      
    }
  }

  .component-previous-mural-image  {
    @extend .flex-center;

    padding-right: 25px;
    padding-left: 25px;
    
    grid-area: nav;
    

    @include media-breakpoint-up(lg) {
      padding:0px;
      border-top:3px solid white;
    border-right: 3px solid white;
    }
  }

  .component-previous-mural-text {
    @extend .px-3;

    grid-area: main;

    text-align: left;
    justify-self: start;
    align-self: start;
    line-height: 1.25;
    
    @include media-breakpoint-up(lg) {
      border-top:3px solid white;
      
    }

    p{
      font-size: 16px;
      font-weight: 400;
      line-height: 21.65px;

      padding: 5px 15px 45px 15px;
      
      color: white;

      @include media-breakpoint-up(lg) {
        padding: 38px 40px;
        font-size: 20px;
      font-weight: 400;
      line-height: 27.06px;
      }
    }

  }

  .component-previous-mural-counter {
    @extend .flex-center;

    grid-area: foot;
    margin-bottom:   15px;

    @include media-breakpoint-up(md) {
      margin-bottom:   0px
    }
  }
}

.counter-row{
  width: 100%;

  div{
    
    font-size: 32px;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 31px;
      margin-bottom: 31px;

    &:nth-child(1){
      color: #F9F15A;
    }
    &:nth-child(2){
      color: #F9F15A;
    }
    &:nth-child(3){
      color: #66DEF4;
    }

    span{
      font-size: 14px;
      font-weight: 500;
    }
  }
  
  @include media-breakpoint-up(lg) {
    div{
    
      font-size: 57px;
      display: flex;
      flex-direction: column;
      text-align: center;
      
  

      span{
        font-size: 24px;
        font-weight: 500;
      }
    }
  }

  
}



