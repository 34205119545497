.artwork-timer {
  color: $color-white;
  margin: 0 auto;
  max-width: 100rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  @include media-breakpoint-up(md) {
    flex-wrap: nowrap;
  }
}

.artwork-timer-item {
  text-align: center;

  &--days {
    margin-bottom: 3rem;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 25rem;
    }

    h2 {
      font-family: $timer-font;
      font-weight: 700;
      font-size: 6.4rem;
      @include media-breakpoint-up(md) {
        font-size: 4.8rem;
      }
      @include media-breakpoint-up(lg) {
        font-size: 8.8rem;
      }
    }

    h3 {
      font-family: $timer-font;
      font-weight: 700;
      font-size: 4rem;
      @include media-breakpoint-up(md) {
        font-size: 2rem;
      }
      @include media-breakpoint-up(lg) {
        font-size: 4rem;
      }
    }
  }

  &--hours,
  &--minutes,
  &--separator {
    h2 {
      font-family: $timer-font;
      font-weight: 700;
      font-size: 4.8rem;
      @include media-breakpoint-up(lg) {
        font-size: 8.8rem;
      }
    }

    h3 {
      font-family: $timer-font;
      font-weight: 700;
      font-size: 2rem;
      @include media-breakpoint-up(lg) {
        font-size: 4rem;
      }
    }

  }

  &--hours,
  &--minutes {
    width: 13rem;

    @include media-breakpoint-up(md) {
      width: 25rem;
    }
  }
}

.artwork-about {
  position: relative;
  padding-top: 3rem;
  padding-bottom: 3rem;
  // background-image: url("../../static/pixel-transition@2x.svg");
  // background-repeat: repeat-x;

  background-color: white;

  @include media-breakpoint-up(md) {
    padding-top: 11rem;
  padding-bottom: 11rem;
  }

  

  h2 {
    margin-bottom: 5rem;
  }

  .absolute-pixel--1 {
    bottom: 0;
    right: 0;

    @include media-breakpoint-up(md) {
      right: 4rem;
    }

    .pixel--1 {
      bottom: 2rem;
      right: 0;
      background-color: $color-accent;
      @include media-breakpoint-up(md) {
      }
    }

    .pixel--2 {
      bottom: 0;
      right: 2rem;
      background-color: $color-primary-dark;
    }

    .pixel--3 {
      bottom: 0;
      right: 4rem;
    }
  }
}

.image-art-grid {
  object-fit: cover;
  width: 100%;
  object-position: 50% 50%;
}


.art2022-g1 {

  background-color: #fff0;
  display: grid;
  grid-template-columns: 100% !important;
  grid-template-rows: auto;
  
  padding:20px 20px 0px 20px !important;
  grid-template-areas: "nav"
                       "  main";
                       

  @include media-breakpoint-up(lg) {
    background-color: #fff0;
  border: 3px solid black ;
  display: grid;
  grid-template-columns: 40% 60% !important;
  grid-template-rows: auto;
  padding: 0px !important;
  grid-template-areas: 
                       "nav  main";
  }

  

  .art2022-g1-image  {
    @extend .flex-center;
    border: 3px solid black;
    
    grid-area: nav;
    

    @include media-breakpoint-up(lg) {
      border: none;
      border-right: 3px solid black;
      padding:0px;
      
    }
  }

  .art2022-g1-text {
    @extend .px-3;
    padding: 20px 20px 50px 20px !important;
    grid-area: main;
    border: 3px solid black;
    border-top:none;
    text-align: left;
    justify-self: start;
    align-self: start;
    

    

    @include media-breakpoint-up(lg) {
      padding: 50px 80px !important;
      background-color: #F6EFE566;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: none;
    }

    h3{
      

      line-height: 26.54px;
      margin-bottom: 25px;
    font-size: 20px;
    font-weight: 700;
    font-family: $degular-font;
    color: #000;

      @include media-breakpoint-up(lg) {
        font-family: $degular-font;
      margin-bottom: 37px;
      font-weight: 700;
      font-size: 36px;
      color: #000;
      }

      @include media-breakpoint-up(md) {
        font-family: $degular-font;
      margin-bottom: 37px;
      font-weight: 700;
      font-size: 36px;
      color: #000;
      }

    }
    p{
      font-family: $worksans-font;
      margin-bottom: 15px;
      font-weight: 400;
      font-size: 16px;
      color: #000;
      line-height: 21.65px;
    }

    

  }

  
}

.art2022-g2 {

  background-color: #fff0;
  display: grid;
  grid-template-columns: 100%  !important;
  grid-template-rows: auto;
  padding:0px 20px 20px 20px !important;
  grid-template-areas: "nav"
                       "main   ";
                       

  @include media-breakpoint-up(lg) {
    background-color: #fff0;
  border: 3px solid black ;
  border-top:none !important;
  border-bottom: 3px solid black !important;
  display: grid;
  grid-template-columns: 60% 40%  !important;
  grid-template-rows: auto;
  padding: 0px !important;
  grid-template-areas: 
                       "main nav  ";
  }

  

  .art2022-g2-image  {
    @extend .flex-center;

    border: 3px solid black;
    border-top:none;
    background-color: gray;
    grid-area: nav;
    
    

    @include media-breakpoint-up(lg) {
      
      border:none;
      border-left: 3px solid black;
      background-color: gray;
    }
  }

  .art2022-g2-text {
    @extend .px-3;
    padding: 20px 20px 50px 20px !important;
    grid-area: main;
    border: 3px solid black;
    border-top:none;

    text-align: left;
    justify-self: start;
    align-self: start;
    line-height: 1.25;
    @include media-breakpoint-up(lg) {
      padding: 50px 80px !important;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border:none
      
    }

    h3{
      

      line-height: 26.54px;
    font-size: 20px;
    font-weight: 700;
    font-family: $degular-font;
    color: #000;

    @include media-breakpoint-up(md) {
      font-family: $degular-font;
    margin-bottom: 37px;
    font-weight: 700;
    font-size: 36px;
    color: #000;
    }

      @include media-breakpoint-up(lg) {
        font-family: $degular-font;
      margin-bottom: 37px;
      font-weight: 700;
      font-size: 36px;
      color: #000;
      }

    }
    p{
      font-family: $worksans-font;
      margin-bottom: 15px;
      font-weight: 400;
      font-size: 16px;
      color: #000;
    }
    

  }

  
}

.art-image{
  display: flex;
  justify-content: center;
  padding: 0px 57px ;
  @include media-breakpoint-up(md) {
    padding:  0px;
  }
}

.art-location{
  display: flex;
  flex-direction: column;
  justify-content: center;

  div{
    h3{
        font-size: 20px;
        font-weight: 700;
        color: black;
        line-height: 26.54px;
        text-align: center;
        margin: 0 17px 10px 17px;
        font-family: $degular-font;
        @include media-breakpoint-up(md) {
          margin: 0px 0px 52px 0px;
          font-size: 36px;
        font-weight: 700;
        color: black;
        text-align: left;
          
        }
    }
  }

  div{
    p{
      font-weight: 400;
      font-size: 16px;
      color: black;
      line-height: 21.65px;
      @include media-breakpoint-up(md) {
        font-weight: 400;
      font-size: 20px;
      color: black;
      line-height: 27.06px;
        
      }
    }
  }

  div{
    a{
        margin-bottom: 40px;
    }
  }

}

.art-back-1{
  position: absolute;
  top: 39px;
  right: 105px;
}

.art-back-2{
  position: absolute;
  top: 0;
  left: 0;
}

.art-back-3{
  position: absolute;
  right: 0;
  bottom: 0;
}

.art-header{
  
  margin-bottom: 3px;
  @include media-breakpoint-up(md) {
    margin-bottom: 64px;
    
  }
}